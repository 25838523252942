import parsePhoneNumberFromString, {
  CountryCode,
  getCountryCallingCode,
} from 'libphonenumber-js';
import { GridApiError } from './ApiQueryTypes';

export const reformatDate = (date: string) => {
  const newDate = new Date(date);
  return newDate?.toLocaleTimeString('de', {
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};

export const NON_BREAKING_WHITE_SPACE = '\xa0';

/**
Formats a price value to a currency string using the given locale.
@param args.price The price value to be formatted.
@param args.currency The currency code.
@param args.locale The locale.
@returns A string representing the formatted price in the specified currency and locale, or undefined if any of the input arguments is missing.
*/
export const formatPrice = (args: {
  currency: string;
  locale: string;
  price: number;
  style?: any; //TODO: specify the type, string is not correct!!!
}): string | undefined => {
  const { currency, locale, price, style = 'currency' } = args;
  if (!(currency && locale && price)) return undefined;
  return new Intl.NumberFormat(locale, {
    currency,
    style,
  })
    .format(price)
    .replace(NON_BREAKING_WHITE_SPACE, ' ');
};

/**
 *
 * For wrapping `getCountryCallingCode` with error handling.
 * Needed because `getCountryCallingCode` can throw an error and break the app
 * if the country code passed as its argument is not found.
 * That might happen if the country code from our own source is not in sync with
 * the country codes supported by the `libphonenumber-js` library.
 *
 * @param countryCode - The country code for which to fetch the calling code.
 * @returns The calling code for the given country code.
 */
export function safeGetCountryCallingCode(
  countryCode: string,
): string | undefined {
  try {
    // Attempt to cast to CountryCode and call getCountryCallingCode
    return `+${getCountryCallingCode(countryCode as CountryCode)}`;
  } catch (error) {
    return undefined;
  }
}

/**
 * For handling the default phone value from the profile data,
 * which can come in different formats and be even null or invalid.
 * @param defaultPhoneValue The default phone value to parse and format.
 * @param defaultCountry The default country code to use for parsing the phone number.
 * @returns The formatted default values for the international phone input.
 */
export const parsePhoneNumber = (
  defaultPhoneValue: string | null | undefined,
  defaultCountry: string,
): {
  countryDialCode?: string;
  fullPhoneNumber?: string;
  phoneNumber?: string;
} => {
  if (!defaultPhoneValue) {
    return {
      countryDialCode: safeGetCountryCallingCode(defaultCountry),
      fullPhoneNumber: undefined,
      phoneNumber: '',
    };
  }

  const phoneNumber = parsePhoneNumberFromString(defaultPhoneValue);

  if (!phoneNumber && !!defaultPhoneValue) {
    return {
      countryDialCode: safeGetCountryCallingCode(defaultCountry),
      fullPhoneNumber: `${safeGetCountryCallingCode(
        defaultCountry,
      )}${defaultPhoneValue}`,
      phoneNumber: defaultPhoneValue,
    };
  }

  if (phoneNumber) {
    return {
      countryDialCode: `+${phoneNumber.countryCallingCode}`,
      fullPhoneNumber: phoneNumber.number,
      phoneNumber: phoneNumber.nationalNumber,
    };
  }

  return {};
};

export const extractTLDFromHost = (host?: string | null) => {
  if (!host) {
    return undefined;
  }

  return host.includes('localhost')
    ? 'localhost'
    : `${host?.split('.')?.slice(-2).join('.')}`;
};

export const calculateSeededHash = (str: string, seed = 0) => {
  let hash = seed;

  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to a 32-bit integer
  }

  return Math.abs(hash);
};

export const getNotificationText = (
  error: GridApiError | unknown,
  defaultKey: string,
): string => {
  const errorText =
    (error as GridApiError)?.response?.data?.error ||
    (error as GridApiError)?.message ||
    defaultKey;

  return errorText.replace(/^Error:\s*/, '');
};

export const retryAsync = async <TResult = unknown>(
  cb: () => TResult,
  config?: {
    retryCount?: number;
  },
): Promise<TResult> => {
  const retryCount = config?.retryCount || 5;

  try {
    const result = await cb();

    return result;
  } catch (error) {
    if (!retryCount) throw error;

    const result = await retryAsync(cb, { retryCount: retryCount - 1 });

    return result;
  }
};

export const formatInitialsFromName = (payload: {
  firstName?: string;
  lastName?: string;
}) => {
  return [
    payload.firstName?.toUpperCase()[0],
    payload.lastName?.toUpperCase()[0],
  ]
    .filter(Boolean)
    .join('');
};
