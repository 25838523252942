/* tslint:disable */
/* eslint-disable */
/**
 * Admin | GRID API
 * Manage users, machines and companies. The is the admin API of GRID. It is supposed to be used by the admin users through the admin interface. To be able to access the API, you need to be authenticated with a GRIMME Entra ID account and have the necessary roles. The minimum required role is `Read`. To write basic data, `WriteBasic` is needed. To write advanced data (Roles and Permissions), `WriteFull` is needed.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: digihub@grimme.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'countryCode': string;
}
/**
 * 
 * @export
 * @interface ClarificationAuthorDto
 */
export interface ClarificationAuthorDto {
    /**
     * 
     * @type {string}
     * @memberof ClarificationAuthorDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClarificationAuthorDto
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface ClarificationCreationRequestDto
 */
export interface ClarificationCreationRequestDto {
    /**
     * Id of the entity to create clarification for
     * @type {string}
     * @memberof ClarificationCreationRequestDto
     */
    'clarificationEntityId': string;
    /**
     * Type of the entity to create clarification for
     * @type {string}
     * @memberof ClarificationCreationRequestDto
     */
    'clarificationEntityType': ClarificationCreationRequestDtoClarificationEntityTypeEnum;
}

export const ClarificationCreationRequestDtoClarificationEntityTypeEnum = {
    CompanyVerification: 'company_verification',
    MachineMapping: 'machine_mapping'
} as const;

export type ClarificationCreationRequestDtoClarificationEntityTypeEnum = typeof ClarificationCreationRequestDtoClarificationEntityTypeEnum[keyof typeof ClarificationCreationRequestDtoClarificationEntityTypeEnum];

/**
 * 
 * @export
 * @interface ClarificationCreationResponseDto
 */
export interface ClarificationCreationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ClarificationCreationResponseDto
     */
    'clarificationEntityId': string;
    /**
     * 
     * @type {string}
     * @memberof ClarificationCreationResponseDto
     */
    'clarificationEntityType': ClarificationCreationResponseDtoClarificationEntityTypeEnum;
    /**
     * 
     * @type {ClarificationAuthorDto}
     * @memberof ClarificationCreationResponseDto
     */
    'author': ClarificationAuthorDto;
    /**
     * 
     * @type {string}
     * @memberof ClarificationCreationResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClarificationCreationResponseDto
     */
    'updatedAt': string;
}

export const ClarificationCreationResponseDtoClarificationEntityTypeEnum = {
    CompanyVerification: 'company_verification',
    MachineMapping: 'machine_mapping'
} as const;

export type ClarificationCreationResponseDtoClarificationEntityTypeEnum = typeof ClarificationCreationResponseDtoClarificationEntityTypeEnum[keyof typeof ClarificationCreationResponseDtoClarificationEntityTypeEnum];

/**
 * 
 * @export
 * @interface ClarificationDto
 */
export interface ClarificationDto {
    /**
     * 
     * @type {string}
     * @memberof ClarificationDto
     */
    'clarificationEntityId': string;
    /**
     * 
     * @type {string}
     * @memberof ClarificationDto
     */
    'clarificationEntityType': ClarificationDtoClarificationEntityTypeEnum;
    /**
     * 
     * @type {ClarificationAuthorDto}
     * @memberof ClarificationDto
     */
    'author': ClarificationAuthorDto;
    /**
     * 
     * @type {string}
     * @memberof ClarificationDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClarificationDto
     */
    'updatedAt': string;
}

export const ClarificationDtoClarificationEntityTypeEnum = {
    CompanyVerification: 'company_verification',
    MachineMapping: 'machine_mapping'
} as const;

export type ClarificationDtoClarificationEntityTypeEnum = typeof ClarificationDtoClarificationEntityTypeEnum[keyof typeof ClarificationDtoClarificationEntityTypeEnum];

/**
 * 
 * @export
 * @interface ClarificationGetResponseDto
 */
export interface ClarificationGetResponseDto {
    /**
     * 
     * @type {ClarificationDto}
     * @memberof ClarificationGetResponseDto
     */
    'clarification': ClarificationDto | null;
}
/**
 * 
 * @export
 * @interface ClarificationsListResponseDto
 */
export interface ClarificationsListResponseDto {
    /**
     * 
     * @type {Array<ClarificationDto>}
     * @memberof ClarificationsListResponseDto
     */
    'clarifications': Array<ClarificationDto>;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'businessRelationType': CompanyBusinessRelationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'businessRelationId': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'dealerBusinessRelationId': string;
}

export const CompanyBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type CompanyBusinessRelationTypeEnum = typeof CompanyBusinessRelationTypeEnum[keyof typeof CompanyBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface CompanyAddressDto
 */
export interface CompanyAddressDto {
    /**
     * The company address city.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'city': string;
    /**
     * The company address country code.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'countryCode': string;
    /**
     * The company address street.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'street': string;
    /**
     * The company address zip code.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface CompanyContactDto
 */
export interface CompanyContactDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'cellularPhone': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'telefax': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'phoneLocal': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyContactDto
     */
    'nativeLanguage': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyContactDto
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof CompanyDto
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof CompanyDto
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof CompanyDto
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof CompanyDto
     */
    'businessRelationType'?: CompanyDtoBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof CompanyDto
     */
    'dealerBusinessRelationId'?: string | null;
}

export const CompanyDtoBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type CompanyDtoBusinessRelationTypeEnum = typeof CompanyDtoBusinessRelationTypeEnum[keyof typeof CompanyDtoBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface CompanyMachineRequestDto
 */
export interface CompanyMachineRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyMachineRequestDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMachineRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMachineRequestDto
     */
    'businessRelationId': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMachineRequestDto
     */
    'businessRelationType': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMachineRequestDto
     */
    'dealerBusinessRelationId'?: string;
    /**
     * 
     * @type {CompanyAddressDto}
     * @memberof CompanyMachineRequestDto
     */
    'primaryAddress': CompanyAddressDto | null;
    /**
     * 
     * @type {Array<MachineOwnership>}
     * @memberof CompanyMachineRequestDto
     */
    'machineOwnerships': Array<MachineOwnership>;
}
/**
 * 
 * @export
 * @interface CompanyWithAddressDto
 */
export interface CompanyWithAddressDto {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'businessRelationType'?: CompanyWithAddressDtoBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'dealerBusinessRelationId'?: string | null;
    /**
     * 
     * @type {CompanyWithAddressDtoAddress}
     * @memberof CompanyWithAddressDto
     */
    'address'?: CompanyWithAddressDtoAddress | null;
}

export const CompanyWithAddressDtoBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type CompanyWithAddressDtoBusinessRelationTypeEnum = typeof CompanyWithAddressDtoBusinessRelationTypeEnum[keyof typeof CompanyWithAddressDtoBusinessRelationTypeEnum];

/**
 * The primary address of the company.
 * @export
 * @interface CompanyWithAddressDtoAddress
 */
export interface CompanyWithAddressDtoAddress {
    /**
     * The company address city.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'city': string;
    /**
     * The company address country code.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'countryCode': string;
    /**
     * The company address street.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'street': string;
    /**
     * The company address zip code.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface ConfirmAccountInvitationRequestDto
 */
export interface ConfirmAccountInvitationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountInvitationRequestDto
     */
    'verificationCode': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountInvitationRequestDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountInvitationRequestDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountInvitationRequestDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ConfirmAccountInvitationResponseDto
 */
export interface ConfirmAccountInvitationResponseDto {
    /**
     * 
     * @type {ConfirmAccountInvitationResponseDtoData}
     * @memberof ConfirmAccountInvitationResponseDto
     */
    'data': ConfirmAccountInvitationResponseDtoData;
}
/**
 * 
 * @export
 * @interface ConfirmAccountInvitationResponseDtoData
 */
export interface ConfirmAccountInvitationResponseDtoData {
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountInvitationResponseDtoData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountInvitationResponseDtoData
     */
    'companyId'?: string;
}
/**
 * 
 * @export
 * @interface ConfirmEmailRequestDto
 */
export interface ConfirmEmailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailRequestDto
     */
    'confirmationCode': string;
}
/**
 * 
 * @export
 * @interface ConfirmEmailResponseDto
 */
export interface ConfirmEmailResponseDto {
    /**
     * 
     * @type {ConfirmEmailResponseDtoData}
     * @memberof ConfirmEmailResponseDto
     */
    'data': ConfirmEmailResponseDtoData;
}
/**
 * 
 * @export
 * @interface ConfirmEmailResponseDtoData
 */
export interface ConfirmEmailResponseDtoData {
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailResponseDtoData
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CreateAccountFromInvitationDto
 */
export interface CreateAccountFromInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromInvitationDto
     */
    'preferredLanguageCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromInvitationDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromInvitationDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromInvitationDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromInvitationDto
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface CreateAccountInvitationResponseDto
 */
export interface CreateAccountInvitationResponseDto {
    /**
     * 
     * @type {CreateAccountInvitationResponseDtoData}
     * @memberof CreateAccountInvitationResponseDto
     */
    'data': CreateAccountInvitationResponseDtoData;
}
/**
 * 
 * @export
 * @interface CreateAccountInvitationResponseDtoData
 */
export interface CreateAccountInvitationResponseDtoData {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInvitationResponseDtoData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInvitationResponseDtoData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInvitationResponseDtoData
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInvitationResponseDtoData
     */
    'preferredLanguageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInvitationResponseDtoData
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountInvitationResponseDtoData
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface CreateCompanyDto
 */
export interface CreateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'businessRelationId': string;
    /**
     * 
     * @type {AddressDto}
     * @memberof CreateCompanyDto
     */
    'address': AddressDto;
}
/**
 * 
 * @export
 * @interface CreateCompanyResultDto
 */
export interface CreateCompanyResultDto {
    /**
     * 
     * @type {ConfirmEmailResponseDtoData}
     * @memberof CreateCompanyResultDto
     */
    'data': ConfirmEmailResponseDtoData;
}
/**
 * 
 * @export
 * @interface CreateMachineOwnershipBodyDto
 */
export interface CreateMachineOwnershipBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMachineOwnershipBodyDto
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMachineOwnershipBodyDto
     */
    'customMachineName'?: string;
}
/**
 * 
 * @export
 * @interface CreateMachineOwnershipResponseDto
 */
export interface CreateMachineOwnershipResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMachineOwnershipResponseDto
     */
    'status': CreateMachineOwnershipResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateMachineOwnershipResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMachineOwnershipResponseDto
     */
    'startedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMachineOwnershipResponseDto
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMachineOwnershipResponseDto
     */
    'machineId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMachineOwnershipResponseDto
     */
    'customMachineName'?: string;
}

export const CreateMachineOwnershipResponseDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Inactive: 'inactive',
    Declined: 'declined'
} as const;

export type CreateMachineOwnershipResponseDtoStatusEnum = typeof CreateMachineOwnershipResponseDtoStatusEnum[keyof typeof CreateMachineOwnershipResponseDtoStatusEnum];

/**
 * 
 * @export
 * @interface CreatePermissionDto
 */
export interface CreatePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePermissionDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePermissionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePermissionDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePermissionDto
     */
    'parentPermissionId'?: string;
}
/**
 * 
 * @export
 * @interface CreateRoleRequestDto
 */
export interface CreateRoleRequestDto {
    /**
     * 
     * @type {Set<string>}
     * @memberof CreateRoleRequestDto
     */
    'publicFor': Set<CreateRoleRequestDtoPublicForEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreateRoleRequestDto
     */
    'defaultFor': Set<CreateRoleRequestDtoDefaultForEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRoleRequestDto
     */
    'metaRole'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    'publicNameTranslationKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    'publicDescriptionTranslationKey'?: string | null;
}

export const CreateRoleRequestDtoPublicForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type CreateRoleRequestDtoPublicForEnum = typeof CreateRoleRequestDtoPublicForEnum[keyof typeof CreateRoleRequestDtoPublicForEnum];
export const CreateRoleRequestDtoDefaultForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type CreateRoleRequestDtoDefaultForEnum = typeof CreateRoleRequestDtoDefaultForEnum[keyof typeof CreateRoleRequestDtoDefaultForEnum];

/**
 * 
 * @export
 * @interface CreateUserRequestDto
 */
export interface CreateUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'preferredLanguageCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'phone'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserResponseDto
 */
export interface CreateUserResponseDto {
    /**
     * 
     * @type {CreateUserResponseDtoData}
     * @memberof CreateUserResponseDto
     */
    'data': CreateUserResponseDtoData;
}
/**
 * 
 * @export
 * @interface CreateUserResponseDtoData
 */
export interface CreateUserResponseDtoData {
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponseDtoData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponseDtoData
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface GetAllCompaniesResponseDto
 */
export interface GetAllCompaniesResponseDto {
    /**
     * 
     * @type {Array<CompanyWithAddressDto>}
     * @memberof GetAllCompaniesResponseDto
     */
    'data': Array<CompanyWithAddressDto>;
    /**
     * 
     * @type {GetAllCompaniesResponseDtoPageInfo}
     * @memberof GetAllCompaniesResponseDto
     */
    'pageInfo': GetAllCompaniesResponseDtoPageInfo;
}
/**
 * 
 * @export
 * @interface GetAllCompaniesResponseDtoPageInfo
 */
export interface GetAllCompaniesResponseDtoPageInfo {
    /**
     * 
     * @type {boolean}
     * @memberof GetAllCompaniesResponseDtoPageInfo
     */
    'hasNextPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllCompaniesResponseDtoPageInfo
     */
    'hasPreviousPage'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAllCompaniesResponseDtoPageInfo
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface GetAllMachineOwnershipsResponseDto
 */
export interface GetAllMachineOwnershipsResponseDto {
    /**
     * 
     * @type {Array<MachineOwnershipResponseDto>}
     * @memberof GetAllMachineOwnershipsResponseDto
     */
    'data': Array<MachineOwnershipResponseDto>;
    /**
     * 
     * @type {GetAllCompaniesResponseDtoPageInfo}
     * @memberof GetAllMachineOwnershipsResponseDto
     */
    'pageInfo': GetAllCompaniesResponseDtoPageInfo;
}
/**
 * 
 * @export
 * @interface GetAllPermissionsResponseDto
 */
export interface GetAllPermissionsResponseDto {
    /**
     * 
     * @type {Array<PermissionResponseDto>}
     * @memberof GetAllPermissionsResponseDto
     */
    'data': Array<PermissionResponseDto>;
    /**
     * 
     * @type {GetAllCompaniesResponseDtoPageInfo}
     * @memberof GetAllPermissionsResponseDto
     */
    'pageInfo': GetAllCompaniesResponseDtoPageInfo;
}
/**
 * 
 * @export
 * @interface GetAllRolesResponseDto
 */
export interface GetAllRolesResponseDto {
    /**
     * 
     * @type {Array<RoleWithPermissionKeys>}
     * @memberof GetAllRolesResponseDto
     */
    'data': Array<RoleWithPermissionKeys>;
    /**
     * 
     * @type {GetAllCompaniesResponseDtoPageInfo}
     * @memberof GetAllRolesResponseDto
     */
    'pageInfo': GetAllCompaniesResponseDtoPageInfo;
}
/**
 * 
 * @export
 * @interface GetAllUsersResponseDto
 */
export interface GetAllUsersResponseDto {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof GetAllUsersResponseDto
     */
    'data': Array<UserDto>;
    /**
     * 
     * @type {GetAllCompaniesResponseDtoPageInfo}
     * @memberof GetAllUsersResponseDto
     */
    'pageInfo': GetAllCompaniesResponseDtoPageInfo;
}
/**
 * 
 * @export
 * @interface GetOnePermissionResponseDto
 */
export interface GetOnePermissionResponseDto {
    /**
     * 
     * @type {Array<GetOnePermissionResponseDto>}
     * @memberof GetOnePermissionResponseDto
     */
    'childPermissions': Array<GetOnePermissionResponseDto>;
    /**
     * 
     * @type {string}
     * @memberof GetOnePermissionResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOnePermissionResponseDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof GetOnePermissionResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetOnePermissionResponseDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetOnePermissionResponseDto
     */
    'parentPermissionId': string | null;
}
/**
 * 
 * @export
 * @interface GetUserResponseDto
 */
export interface GetUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'accountStatus': GetUserResponseDtoAccountStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'preferredLanguageCode': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'identityProviderId': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'companyContactId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserResponseDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponseDto
     */
    'createAt': string;
    /**
     * 
     * @type {Company}
     * @memberof GetUserResponseDto
     */
    'company': Company;
    /**
     * 
     * @type {GetUserResponseDtoRoles}
     * @memberof GetUserResponseDto
     */
    'roles': GetUserResponseDtoRoles;
}

export const GetUserResponseDtoAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;

export type GetUserResponseDtoAccountStatusEnum = typeof GetUserResponseDtoAccountStatusEnum[keyof typeof GetUserResponseDtoAccountStatusEnum];

/**
 * 
 * @export
 * @interface GetUserResponseDtoRoles
 */
export interface GetUserResponseDtoRoles {
    /**
     * 
     * @type {Array<Role>}
     * @memberof GetUserResponseDtoRoles
     */
    'assigned'?: Array<Role>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof GetUserResponseDtoRoles
     */
    'default'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface InvitationsControllerVerifyAccountInvitation400Response
 */
export interface InvitationsControllerVerifyAccountInvitation400Response {
    /**
     * 
     * @type {InvitationsControllerVerifyAccountInvitation400ResponseMetadata}
     * @memberof InvitationsControllerVerifyAccountInvitation400Response
     */
    'metadata'?: InvitationsControllerVerifyAccountInvitation400ResponseMetadata | null;
}
/**
 * 
 * @export
 * @interface InvitationsControllerVerifyAccountInvitation400ResponseMetadata
 */
export interface InvitationsControllerVerifyAccountInvitation400ResponseMetadata {
    /**
     * 
     * @type {Enum}
     * @memberof InvitationsControllerVerifyAccountInvitation400ResponseMetadata
     */
    'reason'?: InvitationsControllerVerifyAccountInvitation400ResponseMetadataReasonEnum | null;
}

export const InvitationsControllerVerifyAccountInvitation400ResponseMetadataReasonEnum = {
    InvalidToken: 'invalid_token'
} as const;

export type InvitationsControllerVerifyAccountInvitation400ResponseMetadataReasonEnum = typeof InvitationsControllerVerifyAccountInvitation400ResponseMetadataReasonEnum[keyof typeof InvitationsControllerVerifyAccountInvitation400ResponseMetadataReasonEnum];

/**
 * 
 * @export
 * @interface Machine
 */
export interface Machine {
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Machine
     */
    'model': string;
    /**
     * 
     * @type {number}
     * @memberof Machine
     */
    'yearOfConstruction': number;
    /**
     * 
     * @type {boolean}
     * @memberof Machine
     */
    'hasGsc': boolean;
}
/**
 * 
 * @export
 * @interface MachineBySerialResponseDto
 */
export interface MachineBySerialResponseDto {
    /**
     * 
     * @type {CompanyDto}
     * @memberof MachineBySerialResponseDto
     */
    'company': CompanyDto;
    /**
     * 
     * @type {string}
     * @memberof MachineBySerialResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MachineBySerialResponseDto
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MachineBySerialResponseDto
     */
    'model': string;
    /**
     * 
     * @type {number}
     * @memberof MachineBySerialResponseDto
     */
    'yearOfConstruction': number;
    /**
     * 
     * @type {boolean}
     * @memberof MachineBySerialResponseDto
     */
    'hasGsc': boolean;
}
/**
 * 
 * @export
 * @interface MachineHistoryDto
 */
export interface MachineHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof MachineHistoryDto
     */
    'status': MachineHistoryDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MachineHistoryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MachineHistoryDto
     */
    'endedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MachineHistoryDto
     */
    'startedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MachineHistoryDto
     */
    'customMachineName': string;
    /**
     * 
     * @type {MachineHistoryDtoCompany}
     * @memberof MachineHistoryDto
     */
    'company': MachineHistoryDtoCompany;
}

export const MachineHistoryDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Inactive: 'inactive',
    Declined: 'declined'
} as const;

export type MachineHistoryDtoStatusEnum = typeof MachineHistoryDtoStatusEnum[keyof typeof MachineHistoryDtoStatusEnum];

/**
 * 
 * @export
 * @interface MachineHistoryDtoCompany
 */
export interface MachineHistoryDtoCompany {
    /**
     * 
     * @type {string}
     * @memberof MachineHistoryDtoCompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MachineHistoryDtoCompany
     */
    'businessRelationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MachineHistoryDtoCompany
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MachineHistoryResponseDto
 */
export interface MachineHistoryResponseDto {
    /**
     * 
     * @type {Array<MachineHistoryDto>}
     * @memberof MachineHistoryResponseDto
     */
    'data': Array<MachineHistoryDto>;
}
/**
 * 
 * @export
 * @interface MachineOwnership
 */
export interface MachineOwnership {
    /**
     * 
     * @type {string}
     * @memberof MachineOwnership
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnership
     */
    'customMachineName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnership
     */
    'startedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnership
     */
    'endedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnership
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnership
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnership
     */
    'machineId': string;
    /**
     * 
     * @type {Machine}
     * @memberof MachineOwnership
     */
    'machine': Machine;
}
/**
 * 
 * @export
 * @interface MachineOwnershipResponseDto
 */
export interface MachineOwnershipResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MachineOwnershipResponseDto
     */
    'status': MachineOwnershipResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnershipResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnershipResponseDto
     */
    'customMachineName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnershipResponseDto
     */
    'startedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnershipResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnershipResponseDto
     */
    'endedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnershipResponseDto
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MachineOwnershipResponseDto
     */
    'model': string;
    /**
     * 
     * @type {number}
     * @memberof MachineOwnershipResponseDto
     */
    'yearOfConstruction': number;
    /**
     * 
     * @type {CompanyWithAddressDto}
     * @memberof MachineOwnershipResponseDto
     */
    'company': CompanyWithAddressDto;
}

export const MachineOwnershipResponseDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Inactive: 'inactive',
    Declined: 'declined'
} as const;

export type MachineOwnershipResponseDtoStatusEnum = typeof MachineOwnershipResponseDtoStatusEnum[keyof typeof MachineOwnershipResponseDtoStatusEnum];

/**
 * 
 * @export
 * @interface OmitTypeClass
 */
export interface OmitTypeClass {
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    'model': string;
    /**
     * 
     * @type {number}
     * @memberof OmitTypeClass
     */
    'yearOfConstruction': number;
    /**
     * 
     * @type {boolean}
     * @memberof OmitTypeClass
     */
    'hasGsc': boolean;
}
/**
 * 
 * @export
 * @interface PasswordResetRequestDto
 */
export interface PasswordResetRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequestDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PasswordResetResponseDto
 */
export interface PasswordResetResponseDto {
    /**
     * 
     * @type {ResendEmailVerificationResponseDtoData}
     * @memberof PasswordResetResponseDto
     */
    'data': ResendEmailVerificationResponseDtoData;
}
/**
 * 
 * @export
 * @interface PatchRoleDto
 */
export interface PatchRoleDto {
    /**
     * 
     * @type {Set<string>}
     * @memberof PatchRoleDto
     */
    'publicFor': Set<PatchRoleDtoPublicForEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof PatchRoleDto
     */
    'defaultFor': Set<PatchRoleDtoDefaultForEnum>;
    /**
     * Array of Permission UUIDs
     * @type {Array<string>}
     * @memberof PatchRoleDto
     */
    'permissionIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatchRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PatchRoleDto
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchRoleDto
     */
    'metaRole'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchRoleDto
     */
    'publicNameTranslationKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchRoleDto
     */
    'publicDescriptionTranslationKey'?: string | null;
}

export const PatchRoleDtoPublicForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type PatchRoleDtoPublicForEnum = typeof PatchRoleDtoPublicForEnum[keyof typeof PatchRoleDtoPublicForEnum];
export const PatchRoleDtoDefaultForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type PatchRoleDtoDefaultForEnum = typeof PatchRoleDtoDefaultForEnum[keyof typeof PatchRoleDtoDefaultForEnum];

/**
 * 
 * @export
 * @interface PermissionResponseDto
 */
export interface PermissionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponseDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponseDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponseDto
     */
    'parentPermissionId': string | null;
}
/**
 * 
 * @export
 * @interface PickTypeClass
 */
export interface PickTypeClass {
    /**
     * 
     * @type {string}
     * @memberof PickTypeClass
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ResendEmailVerificationRequestDto
 */
export interface ResendEmailVerificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ResendEmailVerificationRequestDto
     */
    'preferredLanguageCode': string;
    /**
     * 
     * @type {string}
     * @memberof ResendEmailVerificationRequestDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ResendEmailVerificationResponseDto
 */
export interface ResendEmailVerificationResponseDto {
    /**
     * 
     * @type {ResendEmailVerificationResponseDtoData}
     * @memberof ResendEmailVerificationResponseDto
     */
    'data': ResendEmailVerificationResponseDtoData;
}
/**
 * 
 * @export
 * @interface ResendEmailVerificationResponseDtoData
 */
export interface ResendEmailVerificationResponseDtoData {
    /**
     * 
     * @type {string}
     * @memberof ResendEmailVerificationResponseDtoData
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {Set<string>}
     * @memberof RoleDto
     */
    'publicFor': Set<RoleDtoPublicForEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof RoleDto
     */
    'defaultFor': Set<RoleDtoDefaultForEnum>;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'metaRole'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'publicNameTranslationKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'publicDescriptionTranslationKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'id': string;
}

export const RoleDtoPublicForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type RoleDtoPublicForEnum = typeof RoleDtoPublicForEnum[keyof typeof RoleDtoPublicForEnum];
export const RoleDtoDefaultForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type RoleDtoDefaultForEnum = typeof RoleDtoDefaultForEnum[keyof typeof RoleDtoDefaultForEnum];

/**
 * 
 * @export
 * @interface RoleWithPermissionKeys
 */
export interface RoleWithPermissionKeys {
    /**
     * 
     * @type {Set<string>}
     * @memberof RoleWithPermissionKeys
     */
    'publicFor': Set<RoleWithPermissionKeysPublicForEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof RoleWithPermissionKeys
     */
    'defaultFor': Set<RoleWithPermissionKeysDefaultForEnum>;
    /**
     * 
     * @type {Array<object>}
     * @memberof RoleWithPermissionKeys
     */
    'permissions': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof RoleWithPermissionKeys
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleWithPermissionKeys
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleWithPermissionKeys
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleWithPermissionKeys
     */
    'metaRole'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleWithPermissionKeys
     */
    'publicNameTranslationKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleWithPermissionKeys
     */
    'publicDescriptionTranslationKey'?: string | null;
}

export const RoleWithPermissionKeysPublicForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type RoleWithPermissionKeysPublicForEnum = typeof RoleWithPermissionKeysPublicForEnum[keyof typeof RoleWithPermissionKeysPublicForEnum];
export const RoleWithPermissionKeysDefaultForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type RoleWithPermissionKeysDefaultForEnum = typeof RoleWithPermissionKeysDefaultForEnum[keyof typeof RoleWithPermissionKeysDefaultForEnum];

/**
 * 
 * @export
 * @interface SearchGredaAddress
 */
export interface SearchGredaAddress {
    /**
     * 
     * @type {string}
     * @memberof SearchGredaAddress
     */
    'street': string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchGredaAddress
     */
    'zipCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchGredaAddress
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchGredaAddress
     */
    'country': string | null;
}
/**
 * 
 * @export
 * @interface SearchGredaCompany
 */
export interface SearchGredaCompany {
    /**
     * 
     * @type {string}
     * @memberof SearchGredaCompany
     */
    'businessRelationId': string;
    /**
     * 
     * @type {string}
     * @memberof SearchGredaCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SearchGredaCompany
     */
    'businessRelationType': SearchGredaCompanyBusinessRelationTypeEnum | null;
    /**
     * 
     * @type {SearchGredaAddress}
     * @memberof SearchGredaCompany
     */
    'address': SearchGredaAddress | null;
    /**
     * Id of the source tenant. E.g: 600 for GRIMME France, 810 for GRIMME Ireland, etc.
     * @type {string}
     * @memberof SearchGredaCompany
     */
    'tenantId': string;
    /**
     * 
     * @type {SearchGredaCompanyCompany}
     * @memberof SearchGredaCompany
     */
    'company': SearchGredaCompanyCompany | null;
}

export const SearchGredaCompanyBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type SearchGredaCompanyBusinessRelationTypeEnum = typeof SearchGredaCompanyBusinessRelationTypeEnum[keyof typeof SearchGredaCompanyBusinessRelationTypeEnum];

/**
 * GRID company related to this GREDA company
 * @export
 * @interface SearchGredaCompanyCompany
 */
export interface SearchGredaCompanyCompany {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof SearchGredaCompanyCompany
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof SearchGredaCompanyCompany
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof SearchGredaCompanyCompany
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof SearchGredaCompanyCompany
     */
    'businessRelationType'?: SearchGredaCompanyCompanyBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof SearchGredaCompanyCompany
     */
    'dealerBusinessRelationId'?: string | null;
}

export const SearchGredaCompanyCompanyBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type SearchGredaCompanyCompanyBusinessRelationTypeEnum = typeof SearchGredaCompanyCompanyBusinessRelationTypeEnum[keyof typeof SearchGredaCompanyCompanyBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface SearchGredaCompanyResponseDto
 */
export interface SearchGredaCompanyResponseDto {
    /**
     * 
     * @type {Array<SearchGredaCompany>}
     * @memberof SearchGredaCompanyResponseDto
     */
    'data': Array<SearchGredaCompany>;
}
/**
 * 
 * @export
 * @interface UpdateCompanyRequestDto
 */
export interface UpdateCompanyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'businessRelationId': string;
}
/**
 * 
 * @export
 * @interface UpdateMachineOwnershipStatusRequestDto
 */
export interface UpdateMachineOwnershipStatusRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMachineOwnershipStatusRequestDto
     */
    'status': UpdateMachineOwnershipStatusRequestDtoStatusEnum;
}

export const UpdateMachineOwnershipStatusRequestDtoStatusEnum = {
    Active: 'active',
    Declined: 'declined',
    Inactive: 'inactive'
} as const;

export type UpdateMachineOwnershipStatusRequestDtoStatusEnum = typeof UpdateMachineOwnershipStatusRequestDtoStatusEnum[keyof typeof UpdateMachineOwnershipStatusRequestDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdatePasswordRequestDto
 */
export interface UpdatePasswordRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequestDto
     */
    'verificationCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequestDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UpdateUserRequestDto
 */
export interface UpdateUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDto
     */
    'contactId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequestDto
     */
    'assignedRoles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * The user\'s unique identifier.
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * The user\'s email.
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * The user\'s first name.
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * The user\'s last name.
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * The user\'s phone number.
     * @type {string}
     * @memberof UserDto
     */
    'phone'?: string | null;
    /**
     * The user\'s preferred language code.
     * @type {string}
     * @memberof UserDto
     */
    'preferredLanguageCode': string;
    /**
     * The ID that the user\'s identity provider uses.
     * @type {string}
     * @memberof UserDto
     */
    'identityProviderId': string;
    /**
     * The ID of the company the user is associated with.
     * @type {string}
     * @memberof UserDto
     */
    'companyId'?: string | null;
    /**
     * The ID of the company contact the user is associated with.
     * @type {string}
     * @memberof UserDto
     */
    'companyContactId'?: string | null;
    /**
     * The user is verified or not.
     * @type {boolean}
     * @memberof UserDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'accountStatus': UserDtoAccountStatusEnum;
    /**
     * Datetime when account was created
     * @type {string}
     * @memberof UserDto
     */
    'createAt': string;
    /**
     * 
     * @type {UserDtoCompany}
     * @memberof UserDto
     */
    'company'?: UserDtoCompany | null;
}

export const UserDtoAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;

export type UserDtoAccountStatusEnum = typeof UserDtoAccountStatusEnum[keyof typeof UserDtoAccountStatusEnum];

/**
 * The company the user is associated with.
 * @export
 * @interface UserDtoCompany
 */
export interface UserDtoCompany {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'businessRelationType'?: UserDtoCompanyBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'dealerBusinessRelationId'?: string | null;
    /**
     * 
     * @type {CompanyWithAddressDtoAddress}
     * @memberof UserDtoCompany
     */
    'address'?: CompanyWithAddressDtoAddress | null;
}

export const UserDtoCompanyBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type UserDtoCompanyBusinessRelationTypeEnum = typeof UserDtoCompanyBusinessRelationTypeEnum[keyof typeof UserDtoCompanyBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface VerifyAccountInvitationRequestDto
 */
export interface VerifyAccountInvitationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyAccountInvitationRequestDto
     */
    'verificationCode': string;
}
/**
 * 
 * @export
 * @interface VerifyAccountInvitationResponseDto
 */
export interface VerifyAccountInvitationResponseDto {
    /**
     * 
     * @type {VerifyAccountInvitationResponseDtoData}
     * @memberof VerifyAccountInvitationResponseDto
     */
    'data': VerifyAccountInvitationResponseDtoData;
}
/**
 * 
 * @export
 * @interface VerifyAccountInvitationResponseDtoData
 */
export interface VerifyAccountInvitationResponseDtoData {
    /**
     * 
     * @type {string}
     * @memberof VerifyAccountInvitationResponseDtoData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyAccountInvitationResponseDtoData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyAccountInvitationResponseDtoData
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyAccountInvitationResponseDtoData
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyAccountInvitationResponseDtoData
     */
    'preferredLanguageCode'?: string;
    /**
     * 
     * @type {VerifyAccountInvitationResponseDtoDataCompany}
     * @memberof VerifyAccountInvitationResponseDtoData
     */
    'company'?: VerifyAccountInvitationResponseDtoDataCompany;
}
/**
 * 
 * @export
 * @interface VerifyAccountInvitationResponseDtoDataCompany
 */
export interface VerifyAccountInvitationResponseDtoDataCompany {
    /**
     * 
     * @type {string}
     * @memberof VerifyAccountInvitationResponseDtoDataCompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyAccountInvitationResponseDtoDataCompany
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface VerifyResponseDto
 */
export interface VerifyResponseDto {
    /**
     * The ID of the company that the user is associated with after verification.
     * @type {string}
     * @memberof VerifyResponseDto
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface VerifyUserRequestDto
 */
export interface VerifyUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyUserRequestDto
     */
    'businessRelationId': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyUserRequestDto
     */
    'contactId'?: string | null;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accepts confirmation code sent through email to verify the account
         * @param {ConfirmEmailRequestDto} confirmEmailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerConfirmEmail: async (confirmEmailRequestDto: ConfirmEmailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmEmailRequestDto' is not null or undefined
            assertParamExists('accountsControllerConfirmEmail', 'confirmEmailRequestDto', confirmEmailRequestDto)
            const localVarPath = `/api/v1/accounts/confirm-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmEmailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create account and send an email for verification
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreate: async (createUserRequestDto: CreateUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequestDto' is not null or undefined
            assertParamExists('accountsControllerCreate', 'createUserRequestDto', createUserRequestDto)
            const localVarPath = `/api/v1/accounts/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend email verification code
         * @param {ResendEmailVerificationRequestDto} resendEmailVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreateEmailVerificationCode: async (resendEmailVerificationRequestDto: ResendEmailVerificationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendEmailVerificationRequestDto' is not null or undefined
            assertParamExists('accountsControllerCreateEmailVerificationCode', 'resendEmailVerificationRequestDto', resendEmailVerificationRequestDto)
            const localVarPath = `/api/v1/accounts/resend-email-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendEmailVerificationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password, email is sent
         * @param {PasswordResetRequestDto} passwordResetRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerResetPassword: async (passwordResetRequestDto: PasswordResetRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequestDto' is not null or undefined
            assertParamExists('accountsControllerResetPassword', 'passwordResetRequestDto', passwordResetRequestDto)
            const localVarPath = `/api/v1/accounts/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password by verifying the verification code
         * @param {UpdatePasswordRequestDto} updatePasswordRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUpdatePassword: async (updatePasswordRequestDto: UpdatePasswordRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordRequestDto' is not null or undefined
            assertParamExists('accountsControllerUpdatePassword', 'updatePasswordRequestDto', updatePasswordRequestDto)
            const localVarPath = `/api/v1/accounts/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept invitation and make the account verified
         * @param {ConfirmAccountInvitationRequestDto} confirmAccountInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsControllerConfirmAccountInvitation: async (confirmAccountInvitationRequestDto: ConfirmAccountInvitationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmAccountInvitationRequestDto' is not null or undefined
            assertParamExists('invitationsControllerConfirmAccountInvitation', 'confirmAccountInvitationRequestDto', confirmAccountInvitationRequestDto)
            const localVarPath = `/api/v1/accounts/invitations/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmAccountInvitationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify if creating account from invitation request is valid
         * @param {VerifyAccountInvitationRequestDto} verifyAccountInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsControllerVerifyAccountInvitation: async (verifyAccountInvitationRequestDto: VerifyAccountInvitationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyAccountInvitationRequestDto' is not null or undefined
            assertParamExists('invitationsControllerVerifyAccountInvitation', 'verifyAccountInvitationRequestDto', verifyAccountInvitationRequestDto)
            const localVarPath = `/api/v1/accounts/invitations/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyAccountInvitationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accepts confirmation code sent through email to verify the account
         * @param {ConfirmEmailRequestDto} confirmEmailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerConfirmEmail(confirmEmailRequestDto: ConfirmEmailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmEmailResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerConfirmEmail(confirmEmailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerConfirmEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create account and send an email for verification
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerCreate(createUserRequestDto: CreateUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerCreate(createUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resend email verification code
         * @param {ResendEmailVerificationRequestDto} resendEmailVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerCreateEmailVerificationCode(resendEmailVerificationRequestDto: ResendEmailVerificationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResendEmailVerificationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerCreateEmailVerificationCode(resendEmailVerificationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerCreateEmailVerificationCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset password, email is sent
         * @param {PasswordResetRequestDto} passwordResetRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerResetPassword(passwordResetRequestDto: PasswordResetRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerResetPassword(passwordResetRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset password by verifying the verification code
         * @param {UpdatePasswordRequestDto} updatePasswordRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerUpdatePassword(updatePasswordRequestDto: UpdatePasswordRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerUpdatePassword(updatePasswordRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsControllerUpdatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Accept invitation and make the account verified
         * @param {ConfirmAccountInvitationRequestDto} confirmAccountInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationsControllerConfirmAccountInvitation(confirmAccountInvitationRequestDto: ConfirmAccountInvitationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmAccountInvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationsControllerConfirmAccountInvitation(confirmAccountInvitationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.invitationsControllerConfirmAccountInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Verify if creating account from invitation request is valid
         * @param {VerifyAccountInvitationRequestDto} verifyAccountInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationsControllerVerifyAccountInvitation(verifyAccountInvitationRequestDto: VerifyAccountInvitationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyAccountInvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationsControllerVerifyAccountInvitation(verifyAccountInvitationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.invitationsControllerVerifyAccountInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Accepts confirmation code sent through email to verify the account
         * @param {ConfirmEmailRequestDto} confirmEmailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerConfirmEmail(confirmEmailRequestDto: ConfirmEmailRequestDto, options?: any): AxiosPromise<ConfirmEmailResponseDto> {
            return localVarFp.accountsControllerConfirmEmail(confirmEmailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create account and send an email for verification
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreate(createUserRequestDto: CreateUserRequestDto, options?: any): AxiosPromise<CreateUserResponseDto> {
            return localVarFp.accountsControllerCreate(createUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend email verification code
         * @param {ResendEmailVerificationRequestDto} resendEmailVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreateEmailVerificationCode(resendEmailVerificationRequestDto: ResendEmailVerificationRequestDto, options?: any): AxiosPromise<ResendEmailVerificationResponseDto> {
            return localVarFp.accountsControllerCreateEmailVerificationCode(resendEmailVerificationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password, email is sent
         * @param {PasswordResetRequestDto} passwordResetRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerResetPassword(passwordResetRequestDto: PasswordResetRequestDto, options?: any): AxiosPromise<PasswordResetResponseDto> {
            return localVarFp.accountsControllerResetPassword(passwordResetRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password by verifying the verification code
         * @param {UpdatePasswordRequestDto} updatePasswordRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUpdatePassword(updatePasswordRequestDto: UpdatePasswordRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.accountsControllerUpdatePassword(updatePasswordRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept invitation and make the account verified
         * @param {ConfirmAccountInvitationRequestDto} confirmAccountInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsControllerConfirmAccountInvitation(confirmAccountInvitationRequestDto: ConfirmAccountInvitationRequestDto, options?: any): AxiosPromise<ConfirmAccountInvitationResponseDto> {
            return localVarFp.invitationsControllerConfirmAccountInvitation(confirmAccountInvitationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify if creating account from invitation request is valid
         * @param {VerifyAccountInvitationRequestDto} verifyAccountInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationsControllerVerifyAccountInvitation(verifyAccountInvitationRequestDto: VerifyAccountInvitationRequestDto, options?: any): AxiosPromise<VerifyAccountInvitationResponseDto> {
            return localVarFp.invitationsControllerVerifyAccountInvitation(verifyAccountInvitationRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @summary Accepts confirmation code sent through email to verify the account
     * @param {ConfirmEmailRequestDto} confirmEmailRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerConfirmEmail(confirmEmailRequestDto: ConfirmEmailRequestDto, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerConfirmEmail(confirmEmailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create account and send an email for verification
     * @param {CreateUserRequestDto} createUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerCreate(createUserRequestDto: CreateUserRequestDto, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerCreate(createUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend email verification code
     * @param {ResendEmailVerificationRequestDto} resendEmailVerificationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerCreateEmailVerificationCode(resendEmailVerificationRequestDto: ResendEmailVerificationRequestDto, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerCreateEmailVerificationCode(resendEmailVerificationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password, email is sent
     * @param {PasswordResetRequestDto} passwordResetRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerResetPassword(passwordResetRequestDto: PasswordResetRequestDto, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerResetPassword(passwordResetRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password by verifying the verification code
     * @param {UpdatePasswordRequestDto} updatePasswordRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerUpdatePassword(updatePasswordRequestDto: UpdatePasswordRequestDto, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerUpdatePassword(updatePasswordRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept invitation and make the account verified
     * @param {ConfirmAccountInvitationRequestDto} confirmAccountInvitationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public invitationsControllerConfirmAccountInvitation(confirmAccountInvitationRequestDto: ConfirmAccountInvitationRequestDto, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).invitationsControllerConfirmAccountInvitation(confirmAccountInvitationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify if creating account from invitation request is valid
     * @param {VerifyAccountInvitationRequestDto} verifyAccountInvitationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public invitationsControllerVerifyAccountInvitation(verifyAccountInvitationRequestDto: VerifyAccountInvitationRequestDto, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).invitationsControllerVerifyAccountInvitation(verifyAccountInvitationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClarificationsApi - axios parameter creator
 * @export
 */
export const ClarificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates clarification for specified entity
         * @param {ClarificationCreationRequestDto} clarificationCreationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clarificationsApiControllerCreateClarification: async (clarificationCreationRequestDto: ClarificationCreationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clarificationCreationRequestDto' is not null or undefined
            assertParamExists('clarificationsApiControllerCreateClarification', 'clarificationCreationRequestDto', clarificationCreationRequestDto)
            const localVarPath = `/api/v1/clarifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clarificationCreationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes clarification for specified entity
         * @param {string} clarificationEntityId Id of the entity to delete clarification for
         * @param {ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum} clarificationEntityType Type of the entity to delete clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clarificationsApiControllerDeleteClarification: async (clarificationEntityId: string, clarificationEntityType: ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clarificationEntityId' is not null or undefined
            assertParamExists('clarificationsApiControllerDeleteClarification', 'clarificationEntityId', clarificationEntityId)
            // verify required parameter 'clarificationEntityType' is not null or undefined
            assertParamExists('clarificationsApiControllerDeleteClarification', 'clarificationEntityType', clarificationEntityType)
            const localVarPath = `/api/v1/clarifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clarificationEntityId !== undefined) {
                localVarQueryParameter['clarificationEntityId'] = clarificationEntityId;
            }

            if (clarificationEntityType !== undefined) {
                localVarQueryParameter['clarificationEntityType'] = clarificationEntityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets clarification for specified entity. Returns null if no clarification found
         * @param {string} clarificationEntityId Id of the entity to get clarification for
         * @param {ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum} clarificationEntityType Type of the entity to get clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clarificationsApiControllerGetClarification: async (clarificationEntityId: string, clarificationEntityType: ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clarificationEntityId' is not null or undefined
            assertParamExists('clarificationsApiControllerGetClarification', 'clarificationEntityId', clarificationEntityId)
            // verify required parameter 'clarificationEntityType' is not null or undefined
            assertParamExists('clarificationsApiControllerGetClarification', 'clarificationEntityType', clarificationEntityType)
            const localVarPath = `/api/v1/clarifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clarificationEntityId !== undefined) {
                localVarQueryParameter['clarificationEntityId'] = clarificationEntityId;
            }

            if (clarificationEntityType !== undefined) {
                localVarQueryParameter['clarificationEntityType'] = clarificationEntityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets clarifications for specified entity ids and type
         * @param {Array<string>} [clarificationEntityIds] Id of the entity to get clarification for
         * @param {ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum} [clarificationEntityType] Type of the entity to get clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clarificationsApiControllerGetClarifications: async (clarificationEntityIds?: Array<string>, clarificationEntityType?: ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clarifications/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clarificationEntityIds) {
                localVarQueryParameter['clarificationEntityIds'] = clarificationEntityIds;
            }

            if (clarificationEntityType !== undefined) {
                localVarQueryParameter['clarificationEntityType'] = clarificationEntityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClarificationsApi - functional programming interface
 * @export
 */
export const ClarificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClarificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates clarification for specified entity
         * @param {ClarificationCreationRequestDto} clarificationCreationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clarificationsApiControllerCreateClarification(clarificationCreationRequestDto: ClarificationCreationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClarificationCreationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clarificationsApiControllerCreateClarification(clarificationCreationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClarificationsApi.clarificationsApiControllerCreateClarification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes clarification for specified entity
         * @param {string} clarificationEntityId Id of the entity to delete clarification for
         * @param {ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum} clarificationEntityType Type of the entity to delete clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clarificationsApiControllerDeleteClarification(clarificationEntityId: string, clarificationEntityType: ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clarificationsApiControllerDeleteClarification(clarificationEntityId, clarificationEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClarificationsApi.clarificationsApiControllerDeleteClarification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets clarification for specified entity. Returns null if no clarification found
         * @param {string} clarificationEntityId Id of the entity to get clarification for
         * @param {ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum} clarificationEntityType Type of the entity to get clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clarificationsApiControllerGetClarification(clarificationEntityId: string, clarificationEntityType: ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClarificationGetResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clarificationsApiControllerGetClarification(clarificationEntityId, clarificationEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClarificationsApi.clarificationsApiControllerGetClarification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets clarifications for specified entity ids and type
         * @param {Array<string>} [clarificationEntityIds] Id of the entity to get clarification for
         * @param {ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum} [clarificationEntityType] Type of the entity to get clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clarificationsApiControllerGetClarifications(clarificationEntityIds?: Array<string>, clarificationEntityType?: ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClarificationsListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clarificationsApiControllerGetClarifications(clarificationEntityIds, clarificationEntityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClarificationsApi.clarificationsApiControllerGetClarifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClarificationsApi - factory interface
 * @export
 */
export const ClarificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClarificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates clarification for specified entity
         * @param {ClarificationCreationRequestDto} clarificationCreationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clarificationsApiControllerCreateClarification(clarificationCreationRequestDto: ClarificationCreationRequestDto, options?: any): AxiosPromise<ClarificationCreationResponseDto> {
            return localVarFp.clarificationsApiControllerCreateClarification(clarificationCreationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes clarification for specified entity
         * @param {string} clarificationEntityId Id of the entity to delete clarification for
         * @param {ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum} clarificationEntityType Type of the entity to delete clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clarificationsApiControllerDeleteClarification(clarificationEntityId: string, clarificationEntityType: ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum, options?: any): AxiosPromise<void> {
            return localVarFp.clarificationsApiControllerDeleteClarification(clarificationEntityId, clarificationEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets clarification for specified entity. Returns null if no clarification found
         * @param {string} clarificationEntityId Id of the entity to get clarification for
         * @param {ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum} clarificationEntityType Type of the entity to get clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clarificationsApiControllerGetClarification(clarificationEntityId: string, clarificationEntityType: ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum, options?: any): AxiosPromise<ClarificationGetResponseDto> {
            return localVarFp.clarificationsApiControllerGetClarification(clarificationEntityId, clarificationEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets clarifications for specified entity ids and type
         * @param {Array<string>} [clarificationEntityIds] Id of the entity to get clarification for
         * @param {ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum} [clarificationEntityType] Type of the entity to get clarification for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clarificationsApiControllerGetClarifications(clarificationEntityIds?: Array<string>, clarificationEntityType?: ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum, options?: any): AxiosPromise<ClarificationsListResponseDto> {
            return localVarFp.clarificationsApiControllerGetClarifications(clarificationEntityIds, clarificationEntityType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClarificationsApi - object-oriented interface
 * @export
 * @class ClarificationsApi
 * @extends {BaseAPI}
 */
export class ClarificationsApi extends BaseAPI {
    /**
     * 
     * @summary Creates clarification for specified entity
     * @param {ClarificationCreationRequestDto} clarificationCreationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClarificationsApi
     */
    public clarificationsApiControllerCreateClarification(clarificationCreationRequestDto: ClarificationCreationRequestDto, options?: RawAxiosRequestConfig) {
        return ClarificationsApiFp(this.configuration).clarificationsApiControllerCreateClarification(clarificationCreationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes clarification for specified entity
     * @param {string} clarificationEntityId Id of the entity to delete clarification for
     * @param {ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum} clarificationEntityType Type of the entity to delete clarification for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClarificationsApi
     */
    public clarificationsApiControllerDeleteClarification(clarificationEntityId: string, clarificationEntityType: ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum, options?: RawAxiosRequestConfig) {
        return ClarificationsApiFp(this.configuration).clarificationsApiControllerDeleteClarification(clarificationEntityId, clarificationEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets clarification for specified entity. Returns null if no clarification found
     * @param {string} clarificationEntityId Id of the entity to get clarification for
     * @param {ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum} clarificationEntityType Type of the entity to get clarification for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClarificationsApi
     */
    public clarificationsApiControllerGetClarification(clarificationEntityId: string, clarificationEntityType: ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum, options?: RawAxiosRequestConfig) {
        return ClarificationsApiFp(this.configuration).clarificationsApiControllerGetClarification(clarificationEntityId, clarificationEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets clarifications for specified entity ids and type
     * @param {Array<string>} [clarificationEntityIds] Id of the entity to get clarification for
     * @param {ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum} [clarificationEntityType] Type of the entity to get clarification for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClarificationsApi
     */
    public clarificationsApiControllerGetClarifications(clarificationEntityIds?: Array<string>, clarificationEntityType?: ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum, options?: RawAxiosRequestConfig) {
        return ClarificationsApiFp(this.configuration).clarificationsApiControllerGetClarifications(clarificationEntityIds, clarificationEntityType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum = {
    CompanyVerification: 'company_verification',
    MachineMapping: 'machine_mapping'
} as const;
export type ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum = typeof ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum[keyof typeof ClarificationsApiControllerDeleteClarificationClarificationEntityTypeEnum];
/**
 * @export
 */
export const ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum = {
    CompanyVerification: 'company_verification',
    MachineMapping: 'machine_mapping'
} as const;
export type ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum = typeof ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum[keyof typeof ClarificationsApiControllerGetClarificationClarificationEntityTypeEnum];
/**
 * @export
 */
export const ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum = {
    CompanyVerification: 'company_verification',
    MachineMapping: 'machine_mapping'
} as const;
export type ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum = typeof ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum[keyof typeof ClarificationsApiControllerGetClarificationsClarificationEntityTypeEnum];


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new company
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerCreate: async (createCompanyDto: CreateCompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyDto' is not null or undefined
            assertParamExists('companiesControllerCreate', 'createCompanyDto', createCompanyDto)
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete company.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerDeleteCompany: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerDeleteCompany', 'id', id)
            const localVarPath = `/api/v1/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of companies with their primary address.
         * @param {number} page 
         * @param {number} perPage 
         * @param {Set<CompaniesControllerFindAllBusinessRelationTypeEnum>} [businessRelationType] 
         * @param {string | null} [name] 
         * @param {string | null} [businessRelationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindAll: async (page: number, perPage: number, businessRelationType?: Set<CompaniesControllerFindAllBusinessRelationTypeEnum>, name?: string | null, businessRelationId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('companiesControllerFindAll', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('companiesControllerFindAll', 'perPage', perPage)
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (businessRelationType) {
                localVarQueryParameter['businessRelationType'] = Array.from(businessRelationType);
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (businessRelationId !== undefined) {
                localVarQueryParameter['businessRelationId'] = businessRelationId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch GREDA for all transfer declarations to get machines by company business relation id
         * @param {string} businessRelationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindMachinesByBusinessRelationId: async (businessRelationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessRelationId' is not null or undefined
            assertParamExists('companiesControllerFindMachinesByBusinessRelationId', 'businessRelationId', businessRelationId)
            const localVarPath = `/api/v1/companies/greda/{businessRelationId}/machines`
                .replace(`{${"businessRelationId"}}`, encodeURIComponent(String(businessRelationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Company with primary address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindOne: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch GREDA for contacts that belong to a company
         * @param {string} businessRelationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetCompanyContacts: async (businessRelationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessRelationId' is not null or undefined
            assertParamExists('companiesControllerGetCompanyContacts', 'businessRelationId', businessRelationId)
            const localVarPath = `/api/v1/companies/greda/{businessRelationId}/contacts`
                .replace(`{${"businessRelationId"}}`, encodeURIComponent(String(businessRelationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ssearch GREDA for a company
         * @param {string} q The search text. Can be either a company name or BR number. Avoid passing both
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerSearch: async (q: string, take?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('companiesControllerSearch', 'q', q)
            const localVarPath = `/api/v1/companies/greda/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Syncs company business relation type with GREDA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerSync: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerSync', 'id', id)
            const localVarPath = `/api/v1/companies/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update BR number.
         * @param {string} id 
         * @param {UpdateCompanyRequestDto} updateCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdate: async (id: string, updateCompanyRequestDto: UpdateCompanyRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerUpdate', 'id', id)
            // verify required parameter 'updateCompanyRequestDto' is not null or undefined
            assertParamExists('companiesControllerUpdate', 'updateCompanyRequestDto', updateCompanyRequestDto)
            const localVarPath = `/api/v1/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new company
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerCreate(createCompanyDto: CreateCompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCompanyResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerCreate(createCompanyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete company.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerDeleteCompany(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerDeleteCompany(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerDeleteCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List of companies with their primary address.
         * @param {number} page 
         * @param {number} perPage 
         * @param {Set<CompaniesControllerFindAllBusinessRelationTypeEnum>} [businessRelationType] 
         * @param {string | null} [name] 
         * @param {string | null} [businessRelationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerFindAll(page: number, perPage: number, businessRelationType?: Set<CompaniesControllerFindAllBusinessRelationTypeEnum>, name?: string | null, businessRelationId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllCompaniesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerFindAll(page, perPage, businessRelationType, name, businessRelationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch GREDA for all transfer declarations to get machines by company business relation id
         * @param {string} businessRelationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerFindMachinesByBusinessRelationId(businessRelationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OmitTypeClass>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerFindMachinesByBusinessRelationId(businessRelationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerFindMachinesByBusinessRelationId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Company with primary address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerFindOne(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAddressDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerFindOne(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerFindOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch GREDA for contacts that belong to a company
         * @param {string} businessRelationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerGetCompanyContacts(businessRelationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyContactDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerGetCompanyContacts(businessRelationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerGetCompanyContacts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Ssearch GREDA for a company
         * @param {string} q The search text. Can be either a company name or BR number. Avoid passing both
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerSearch(q: string, take?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchGredaCompanyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerSearch(q, take, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Syncs company business relation type with GREDA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerSync(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerSync(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerSync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update BR number.
         * @param {string} id 
         * @param {UpdateCompanyRequestDto} updateCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerUpdate(id: string, updateCompanyRequestDto: UpdateCompanyRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerUpdate(id, updateCompanyRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.companiesControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new company
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerCreate(createCompanyDto: CreateCompanyDto, options?: any): AxiosPromise<CreateCompanyResultDto> {
            return localVarFp.companiesControllerCreate(createCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete company.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerDeleteCompany(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companiesControllerDeleteCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of companies with their primary address.
         * @param {number} page 
         * @param {number} perPage 
         * @param {Set<CompaniesControllerFindAllBusinessRelationTypeEnum>} [businessRelationType] 
         * @param {string | null} [name] 
         * @param {string | null} [businessRelationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindAll(page: number, perPage: number, businessRelationType?: Set<CompaniesControllerFindAllBusinessRelationTypeEnum>, name?: string | null, businessRelationId?: string | null, options?: any): AxiosPromise<GetAllCompaniesResponseDto> {
            return localVarFp.companiesControllerFindAll(page, perPage, businessRelationType, name, businessRelationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch GREDA for all transfer declarations to get machines by company business relation id
         * @param {string} businessRelationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindMachinesByBusinessRelationId(businessRelationId: string, options?: any): AxiosPromise<Array<OmitTypeClass>> {
            return localVarFp.companiesControllerFindMachinesByBusinessRelationId(businessRelationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Company with primary address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindOne(id: string, options?: any): AxiosPromise<CompanyAddressDto> {
            return localVarFp.companiesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch GREDA for contacts that belong to a company
         * @param {string} businessRelationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerGetCompanyContacts(businessRelationId: string, options?: any): AxiosPromise<Array<CompanyContactDto>> {
            return localVarFp.companiesControllerGetCompanyContacts(businessRelationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ssearch GREDA for a company
         * @param {string} q The search text. Can be either a company name or BR number. Avoid passing both
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerSearch(q: string, take?: number, options?: any): AxiosPromise<SearchGredaCompanyResponseDto> {
            return localVarFp.companiesControllerSearch(q, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Syncs company business relation type with GREDA
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerSync(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companiesControllerSync(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update BR number.
         * @param {string} id 
         * @param {UpdateCompanyRequestDto} updateCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdate(id: string, updateCompanyRequestDto: UpdateCompanyRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.companiesControllerUpdate(id, updateCompanyRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new company
     * @param {CreateCompanyDto} createCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerCreate(createCompanyDto: CreateCompanyDto, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerCreate(createCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete company.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerDeleteCompany(id: string, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerDeleteCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of companies with their primary address.
     * @param {number} page 
     * @param {number} perPage 
     * @param {Set<CompaniesControllerFindAllBusinessRelationTypeEnum>} [businessRelationType] 
     * @param {string | null} [name] 
     * @param {string | null} [businessRelationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerFindAll(page: number, perPage: number, businessRelationType?: Set<CompaniesControllerFindAllBusinessRelationTypeEnum>, name?: string | null, businessRelationId?: string | null, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerFindAll(page, perPage, businessRelationType, name, businessRelationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch GREDA for all transfer declarations to get machines by company business relation id
     * @param {string} businessRelationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerFindMachinesByBusinessRelationId(businessRelationId: string, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerFindMachinesByBusinessRelationId(businessRelationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Company with primary address
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerFindOne(id: string, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch GREDA for contacts that belong to a company
     * @param {string} businessRelationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerGetCompanyContacts(businessRelationId: string, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerGetCompanyContacts(businessRelationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ssearch GREDA for a company
     * @param {string} q The search text. Can be either a company name or BR number. Avoid passing both
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerSearch(q: string, take?: number, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerSearch(q, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Syncs company business relation type with GREDA
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerSync(id: string, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerSync(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update BR number.
     * @param {string} id 
     * @param {UpdateCompanyRequestDto} updateCompanyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companiesControllerUpdate(id: string, updateCompanyRequestDto: UpdateCompanyRequestDto, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companiesControllerUpdate(id, updateCompanyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CompaniesControllerFindAllBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;
export type CompaniesControllerFindAllBusinessRelationTypeEnum = typeof CompaniesControllerFindAllBusinessRelationTypeEnum[keyof typeof CompaniesControllerFindAllBusinessRelationTypeEnum];


/**
 * MachinesApi - axios parameter creator
 * @export
 */
export const MachinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new ownership for a machine. Calling this endpoint will deactivate any active ownership for the machine and create a new active one for the given company.
         * @summary Create a machine ownership
         * @param {string} serialNumber 
         * @param {CreateMachineOwnershipBodyDto} createMachineOwnershipBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerCreateOwnership: async (serialNumber: string, createMachineOwnershipBodyDto: CreateMachineOwnershipBodyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('machinesControllerCreateOwnership', 'serialNumber', serialNumber)
            // verify required parameter 'createMachineOwnershipBodyDto' is not null or undefined
            assertParamExists('machinesControllerCreateOwnership', 'createMachineOwnershipBodyDto', createMachineOwnershipBodyDto)
            const localVarPath = `/api/v1/machines/{serialNumber}/ownerships`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMachineOwnershipBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of machines with their primary address.
         * @param {number} page 
         * @param {number} perPage 
         * @param {string | null} [customMachineName] 
         * @param {string | null} [model] 
         * @param {string | null} [serialNumber] 
         * @param {string | null} [businessRelationId] 
         * @param {string | null} [companyId] 
         * @param {string | null} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerFindAll: async (page: number, perPage: number, customMachineName?: string | null, model?: string | null, serialNumber?: string | null, businessRelationId?: string | null, companyId?: string | null, companyName?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('machinesControllerFindAll', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('machinesControllerFindAll', 'perPage', perPage)
            const localVarPath = `/api/v1/machines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customMachineName !== undefined) {
                localVarQueryParameter['customMachineName'] = customMachineName;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (serialNumber !== undefined) {
                localVarQueryParameter['serialNumber'] = serialNumber;
            }

            if (businessRelationId !== undefined) {
                localVarQueryParameter['businessRelationId'] = businessRelationId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches machine information from GRID or GREDA. Includes company info as well.
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerFindBySerial: async (serialNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('machinesControllerFindBySerial', 'serialNumber', serialNumber)
            const localVarPath = `/api/v1/machines/serial/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all machine ownership requests grouped by company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerGetMachineRequestsByCompany: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/machines/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all machine ownership requests for a company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerGetMachineRequestsForCompanyId: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('machinesControllerGetMachineRequestsForCompanyId', 'companyId', companyId)
            const localVarPath = `/api/v1/machines/requests/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Machine ownership history
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerHistory: async (serialNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('machinesControllerHistory', 'serialNumber', serialNumber)
            const localVarPath = `/api/v1/machines/{serialNumber}/history`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the status of a machine ownership or request. If the status is `active`, it will deactivate any active ownership for the machine and create a new active one for the given company. Inactive or rejected machines ownerships cant be updated.
         * @summary Update machine ownerships/requests status
         * @param {string} machineOwnershipId 
         * @param {string} serialNumber 
         * @param {UpdateMachineOwnershipStatusRequestDto} updateMachineOwnershipStatusRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerUpdateStatus: async (machineOwnershipId: string, serialNumber: string, updateMachineOwnershipStatusRequestDto: UpdateMachineOwnershipStatusRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'machineOwnershipId' is not null or undefined
            assertParamExists('machinesControllerUpdateStatus', 'machineOwnershipId', machineOwnershipId)
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('machinesControllerUpdateStatus', 'serialNumber', serialNumber)
            // verify required parameter 'updateMachineOwnershipStatusRequestDto' is not null or undefined
            assertParamExists('machinesControllerUpdateStatus', 'updateMachineOwnershipStatusRequestDto', updateMachineOwnershipStatusRequestDto)
            const localVarPath = `/api/v1/machines/{serialNumber}/ownerships/{machineOwnershipId}`
                .replace(`{${"machineOwnershipId"}}`, encodeURIComponent(String(machineOwnershipId)))
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMachineOwnershipStatusRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MachinesApi - functional programming interface
 * @export
 */
export const MachinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MachinesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new ownership for a machine. Calling this endpoint will deactivate any active ownership for the machine and create a new active one for the given company.
         * @summary Create a machine ownership
         * @param {string} serialNumber 
         * @param {CreateMachineOwnershipBodyDto} createMachineOwnershipBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async machinesControllerCreateOwnership(serialNumber: string, createMachineOwnershipBodyDto: CreateMachineOwnershipBodyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMachineOwnershipResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.machinesControllerCreateOwnership(serialNumber, createMachineOwnershipBodyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachinesApi.machinesControllerCreateOwnership']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List of machines with their primary address.
         * @param {number} page 
         * @param {number} perPage 
         * @param {string | null} [customMachineName] 
         * @param {string | null} [model] 
         * @param {string | null} [serialNumber] 
         * @param {string | null} [businessRelationId] 
         * @param {string | null} [companyId] 
         * @param {string | null} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async machinesControllerFindAll(page: number, perPage: number, customMachineName?: string | null, model?: string | null, serialNumber?: string | null, businessRelationId?: string | null, companyId?: string | null, companyName?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllMachineOwnershipsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.machinesControllerFindAll(page, perPage, customMachineName, model, serialNumber, businessRelationId, companyId, companyName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachinesApi.machinesControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches machine information from GRID or GREDA. Includes company info as well.
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async machinesControllerFindBySerial(serialNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineBySerialResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.machinesControllerFindBySerial(serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachinesApi.machinesControllerFindBySerial']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all machine ownership requests grouped by company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async machinesControllerGetMachineRequestsByCompany(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyMachineRequestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.machinesControllerGetMachineRequestsByCompany(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachinesApi.machinesControllerGetMachineRequestsByCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all machine ownership requests for a company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async machinesControllerGetMachineRequestsForCompanyId(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMachineRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.machinesControllerGetMachineRequestsForCompanyId(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachinesApi.machinesControllerGetMachineRequestsForCompanyId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Machine ownership history
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async machinesControllerHistory(serialNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineHistoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.machinesControllerHistory(serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachinesApi.machinesControllerHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the status of a machine ownership or request. If the status is `active`, it will deactivate any active ownership for the machine and create a new active one for the given company. Inactive or rejected machines ownerships cant be updated.
         * @summary Update machine ownerships/requests status
         * @param {string} machineOwnershipId 
         * @param {string} serialNumber 
         * @param {UpdateMachineOwnershipStatusRequestDto} updateMachineOwnershipStatusRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async machinesControllerUpdateStatus(machineOwnershipId: string, serialNumber: string, updateMachineOwnershipStatusRequestDto: UpdateMachineOwnershipStatusRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.machinesControllerUpdateStatus(machineOwnershipId, serialNumber, updateMachineOwnershipStatusRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachinesApi.machinesControllerUpdateStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MachinesApi - factory interface
 * @export
 */
export const MachinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MachinesApiFp(configuration)
    return {
        /**
         * Creates a new ownership for a machine. Calling this endpoint will deactivate any active ownership for the machine and create a new active one for the given company.
         * @summary Create a machine ownership
         * @param {string} serialNumber 
         * @param {CreateMachineOwnershipBodyDto} createMachineOwnershipBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerCreateOwnership(serialNumber: string, createMachineOwnershipBodyDto: CreateMachineOwnershipBodyDto, options?: any): AxiosPromise<CreateMachineOwnershipResponseDto> {
            return localVarFp.machinesControllerCreateOwnership(serialNumber, createMachineOwnershipBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of machines with their primary address.
         * @param {number} page 
         * @param {number} perPage 
         * @param {string | null} [customMachineName] 
         * @param {string | null} [model] 
         * @param {string | null} [serialNumber] 
         * @param {string | null} [businessRelationId] 
         * @param {string | null} [companyId] 
         * @param {string | null} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerFindAll(page: number, perPage: number, customMachineName?: string | null, model?: string | null, serialNumber?: string | null, businessRelationId?: string | null, companyId?: string | null, companyName?: string | null, options?: any): AxiosPromise<GetAllMachineOwnershipsResponseDto> {
            return localVarFp.machinesControllerFindAll(page, perPage, customMachineName, model, serialNumber, businessRelationId, companyId, companyName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches machine information from GRID or GREDA. Includes company info as well.
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerFindBySerial(serialNumber: string, options?: any): AxiosPromise<MachineBySerialResponseDto> {
            return localVarFp.machinesControllerFindBySerial(serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all machine ownership requests grouped by company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerGetMachineRequestsByCompany(options?: any): AxiosPromise<Array<CompanyMachineRequestDto>> {
            return localVarFp.machinesControllerGetMachineRequestsByCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all machine ownership requests for a company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerGetMachineRequestsForCompanyId(companyId: string, options?: any): AxiosPromise<CompanyMachineRequestDto> {
            return localVarFp.machinesControllerGetMachineRequestsForCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Machine ownership history
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerHistory(serialNumber: string, options?: any): AxiosPromise<MachineHistoryResponseDto> {
            return localVarFp.machinesControllerHistory(serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the status of a machine ownership or request. If the status is `active`, it will deactivate any active ownership for the machine and create a new active one for the given company. Inactive or rejected machines ownerships cant be updated.
         * @summary Update machine ownerships/requests status
         * @param {string} machineOwnershipId 
         * @param {string} serialNumber 
         * @param {UpdateMachineOwnershipStatusRequestDto} updateMachineOwnershipStatusRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesControllerUpdateStatus(machineOwnershipId: string, serialNumber: string, updateMachineOwnershipStatusRequestDto: UpdateMachineOwnershipStatusRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.machinesControllerUpdateStatus(machineOwnershipId, serialNumber, updateMachineOwnershipStatusRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MachinesApi - object-oriented interface
 * @export
 * @class MachinesApi
 * @extends {BaseAPI}
 */
export class MachinesApi extends BaseAPI {
    /**
     * Creates a new ownership for a machine. Calling this endpoint will deactivate any active ownership for the machine and create a new active one for the given company.
     * @summary Create a machine ownership
     * @param {string} serialNumber 
     * @param {CreateMachineOwnershipBodyDto} createMachineOwnershipBodyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachinesApi
     */
    public machinesControllerCreateOwnership(serialNumber: string, createMachineOwnershipBodyDto: CreateMachineOwnershipBodyDto, options?: RawAxiosRequestConfig) {
        return MachinesApiFp(this.configuration).machinesControllerCreateOwnership(serialNumber, createMachineOwnershipBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of machines with their primary address.
     * @param {number} page 
     * @param {number} perPage 
     * @param {string | null} [customMachineName] 
     * @param {string | null} [model] 
     * @param {string | null} [serialNumber] 
     * @param {string | null} [businessRelationId] 
     * @param {string | null} [companyId] 
     * @param {string | null} [companyName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachinesApi
     */
    public machinesControllerFindAll(page: number, perPage: number, customMachineName?: string | null, model?: string | null, serialNumber?: string | null, businessRelationId?: string | null, companyId?: string | null, companyName?: string | null, options?: RawAxiosRequestConfig) {
        return MachinesApiFp(this.configuration).machinesControllerFindAll(page, perPage, customMachineName, model, serialNumber, businessRelationId, companyId, companyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches machine information from GRID or GREDA. Includes company info as well.
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachinesApi
     */
    public machinesControllerFindBySerial(serialNumber: string, options?: RawAxiosRequestConfig) {
        return MachinesApiFp(this.configuration).machinesControllerFindBySerial(serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all machine ownership requests grouped by company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachinesApi
     */
    public machinesControllerGetMachineRequestsByCompany(options?: RawAxiosRequestConfig) {
        return MachinesApiFp(this.configuration).machinesControllerGetMachineRequestsByCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all machine ownership requests for a company
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachinesApi
     */
    public machinesControllerGetMachineRequestsForCompanyId(companyId: string, options?: RawAxiosRequestConfig) {
        return MachinesApiFp(this.configuration).machinesControllerGetMachineRequestsForCompanyId(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Machine ownership history
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachinesApi
     */
    public machinesControllerHistory(serialNumber: string, options?: RawAxiosRequestConfig) {
        return MachinesApiFp(this.configuration).machinesControllerHistory(serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the status of a machine ownership or request. If the status is `active`, it will deactivate any active ownership for the machine and create a new active one for the given company. Inactive or rejected machines ownerships cant be updated.
     * @summary Update machine ownerships/requests status
     * @param {string} machineOwnershipId 
     * @param {string} serialNumber 
     * @param {UpdateMachineOwnershipStatusRequestDto} updateMachineOwnershipStatusRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachinesApi
     */
    public machinesControllerUpdateStatus(machineOwnershipId: string, serialNumber: string, updateMachineOwnershipStatusRequestDto: UpdateMachineOwnershipStatusRequestDto, options?: RawAxiosRequestConfig) {
        return MachinesApiFp(this.configuration).machinesControllerUpdateStatus(machineOwnershipId, serialNumber, updateMachineOwnershipStatusRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create permissions with name, key and parent permission
         * @param {CreatePermissionDto} createPermissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerCreate: async (createPermissionDto: CreatePermissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPermissionDto' is not null or undefined
            assertParamExists('permissionsControllerCreate', 'createPermissionDto', createPermissionDto)
            const localVarPath = `/api/v1/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPermissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all permissions with pagination
         * @param {number} page 
         * @param {number} perPage 
         * @param {string | null} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerFindAll: async (page: number, perPage: number, name?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('permissionsControllerFindAll', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('permissionsControllerFindAll', 'perPage', perPage)
            const localVarPath = `/api/v1/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all permissions with nested child permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerFindAllNested: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/permissions/nested`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one permission with nested permissions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerFindOne: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permissionsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a permission permanently with its child permissions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerRemove: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permissionsControllerRemove', 'id', id)
            const localVarPath = `/api/v1/permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update permission without affecting its associations
         * @param {string} id 
         * @param {CreatePermissionDto} createPermissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerUpdate: async (id: string, createPermissionDto: CreatePermissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permissionsControllerUpdate', 'id', id)
            // verify required parameter 'createPermissionDto' is not null or undefined
            assertParamExists('permissionsControllerUpdate', 'createPermissionDto', createPermissionDto)
            const localVarPath = `/api/v1/permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPermissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create permissions with name, key and parent permission
         * @param {CreatePermissionDto} createPermissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsControllerCreate(createPermissionDto: CreatePermissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsControllerCreate(createPermissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.permissionsControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all permissions with pagination
         * @param {number} page 
         * @param {number} perPage 
         * @param {string | null} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsControllerFindAll(page: number, perPage: number, name?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPermissionsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsControllerFindAll(page, perPage, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.permissionsControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all permissions with nested child permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsControllerFindAllNested(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetOnePermissionResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsControllerFindAllNested(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.permissionsControllerFindAllNested']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get one permission with nested permissions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsControllerFindOne(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOnePermissionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsControllerFindOne(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.permissionsControllerFindOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove a permission permanently with its child permissions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsControllerRemove(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsControllerRemove(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.permissionsControllerRemove']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update permission without affecting its associations
         * @param {string} id 
         * @param {CreatePermissionDto} createPermissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsControllerUpdate(id: string, createPermissionDto: CreatePermissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsControllerUpdate(id, createPermissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.permissionsControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create permissions with name, key and parent permission
         * @param {CreatePermissionDto} createPermissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerCreate(createPermissionDto: CreatePermissionDto, options?: any): AxiosPromise<PermissionResponseDto> {
            return localVarFp.permissionsControllerCreate(createPermissionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all permissions with pagination
         * @param {number} page 
         * @param {number} perPage 
         * @param {string | null} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerFindAll(page: number, perPage: number, name?: string | null, options?: any): AxiosPromise<GetAllPermissionsResponseDto> {
            return localVarFp.permissionsControllerFindAll(page, perPage, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all permissions with nested child permissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerFindAllNested(options?: any): AxiosPromise<Array<GetOnePermissionResponseDto>> {
            return localVarFp.permissionsControllerFindAllNested(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one permission with nested permissions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerFindOne(id: string, options?: any): AxiosPromise<GetOnePermissionResponseDto> {
            return localVarFp.permissionsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a permission permanently with its child permissions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerRemove(id: string, options?: any): AxiosPromise<PermissionResponseDto> {
            return localVarFp.permissionsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update permission without affecting its associations
         * @param {string} id 
         * @param {CreatePermissionDto} createPermissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsControllerUpdate(id: string, createPermissionDto: CreatePermissionDto, options?: any): AxiosPromise<PermissionResponseDto> {
            return localVarFp.permissionsControllerUpdate(id, createPermissionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @summary Create permissions with name, key and parent permission
     * @param {CreatePermissionDto} createPermissionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionsControllerCreate(createPermissionDto: CreatePermissionDto, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionsControllerCreate(createPermissionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all permissions with pagination
     * @param {number} page 
     * @param {number} perPage 
     * @param {string | null} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionsControllerFindAll(page: number, perPage: number, name?: string | null, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionsControllerFindAll(page, perPage, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all permissions with nested child permissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionsControllerFindAllNested(options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionsControllerFindAllNested(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one permission with nested permissions
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionsControllerFindOne(id: string, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a permission permanently with its child permissions
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionsControllerRemove(id: string, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update permission without affecting its associations
     * @param {string} id 
     * @param {CreatePermissionDto} createPermissionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionsControllerUpdate(id: string, createPermissionDto: CreatePermissionDto, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionsControllerUpdate(id, createPermissionDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create role with translation keys and business type scopes
         * @param {CreateRoleRequestDto} createRoleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCreate: async (createRoleRequestDto: CreateRoleRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleRequestDto' is not null or undefined
            assertParamExists('rolesControllerCreate', 'createRoleRequestDto', createRoleRequestDto)
            const localVarPath = `/api/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all roles with flattened permissions list with pagination
         * @param {number} page 
         * @param {number} perPage 
         * @param {RolesControllerFindAllPublicForEnum} [publicFor] group,reseller,customer
         * @param {string | null} [name] 
         * @param {Array<string> | null} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFindAll: async (page: number, perPage: number, publicFor?: RolesControllerFindAllPublicForEnum, name?: string | null, permissions?: Array<string> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('rolesControllerFindAll', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('rolesControllerFindAll', 'perPage', perPage)
            const localVarPath = `/api/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (publicFor !== undefined) {
                localVarQueryParameter['publicFor'] = publicFor;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (permissions) {
                localVarQueryParameter['permissions'] = permissions;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one role with flattened permissions list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFindOne: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchRoleDto} patchRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerPatchOne: async (id: string, patchRoleDto: PatchRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerPatchOne', 'id', id)
            // verify required parameter 'patchRoleDto' is not null or undefined
            assertParamExists('rolesControllerPatchOne', 'patchRoleDto', patchRoleDto)
            const localVarPath = `/api/v1/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a role permanently. Associations with users will be removed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerRemove: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerRemove', 'id', id)
            const localVarPath = `/api/v1/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create role with translation keys and business type scopes
         * @param {CreateRoleRequestDto} createRoleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerCreate(createRoleRequestDto: CreateRoleRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerCreate(createRoleRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all roles with flattened permissions list with pagination
         * @param {number} page 
         * @param {number} perPage 
         * @param {RolesControllerFindAllPublicForEnum} [publicFor] group,reseller,customer
         * @param {string | null} [name] 
         * @param {Array<string> | null} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerFindAll(page: number, perPage: number, publicFor?: RolesControllerFindAllPublicForEnum, name?: string | null, permissions?: Array<string> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllRolesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerFindAll(page, perPage, publicFor, name, permissions, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get one role with flattened permissions list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerFindOne(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleWithPermissionKeys>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerFindOne(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerFindOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchRoleDto} patchRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerPatchOne(id: string, patchRoleDto: PatchRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerPatchOne(id, patchRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerPatchOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove a role permanently. Associations with users will be removed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerRemove(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickTypeClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerRemove(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.rolesControllerRemove']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create role with translation keys and business type scopes
         * @param {CreateRoleRequestDto} createRoleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCreate(createRoleRequestDto: CreateRoleRequestDto, options?: any): AxiosPromise<RoleDto> {
            return localVarFp.rolesControllerCreate(createRoleRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all roles with flattened permissions list with pagination
         * @param {number} page 
         * @param {number} perPage 
         * @param {RolesControllerFindAllPublicForEnum} [publicFor] group,reseller,customer
         * @param {string | null} [name] 
         * @param {Array<string> | null} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFindAll(page: number, perPage: number, publicFor?: RolesControllerFindAllPublicForEnum, name?: string | null, permissions?: Array<string> | null, options?: any): AxiosPromise<GetAllRolesResponseDto> {
            return localVarFp.rolesControllerFindAll(page, perPage, publicFor, name, permissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one role with flattened permissions list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFindOne(id: string, options?: any): AxiosPromise<RoleWithPermissionKeys> {
            return localVarFp.rolesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchRoleDto} patchRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerPatchOne(id: string, patchRoleDto: PatchRoleDto, options?: any): AxiosPromise<void> {
            return localVarFp.rolesControllerPatchOne(id, patchRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a role permanently. Associations with users will be removed.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerRemove(id: string, options?: any): AxiosPromise<PickTypeClass> {
            return localVarFp.rolesControllerRemove(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Create role with translation keys and business type scopes
     * @param {CreateRoleRequestDto} createRoleRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerCreate(createRoleRequestDto: CreateRoleRequestDto, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerCreate(createRoleRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all roles with flattened permissions list with pagination
     * @param {number} page 
     * @param {number} perPage 
     * @param {RolesControllerFindAllPublicForEnum} [publicFor] group,reseller,customer
     * @param {string | null} [name] 
     * @param {Array<string> | null} [permissions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerFindAll(page: number, perPage: number, publicFor?: RolesControllerFindAllPublicForEnum, name?: string | null, permissions?: Array<string> | null, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerFindAll(page, perPage, publicFor, name, permissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one role with flattened permissions list
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerFindOne(id: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatchRoleDto} patchRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerPatchOne(id: string, patchRoleDto: PatchRoleDto, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerPatchOne(id, patchRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a role permanently. Associations with users will be removed.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesControllerRemove(id: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).rolesControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const RolesControllerFindAllPublicForEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;
export type RolesControllerFindAllPublicForEnum = typeof RolesControllerFindAllPublicForEnum[keyof typeof RolesControllerFindAllPublicForEnum];


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates pending account and send them invitation email
         * @param {CreateAccountFromInvitationDto} createAccountFromInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate: async (createAccountFromInvitationDto: CreateAccountFromInvitationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountFromInvitationDto' is not null or undefined
            assertParamExists('usersControllerCreate', 'createAccountFromInvitationDto', createAccountFromInvitationDto)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountFromInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteEmployeeAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerDeleteEmployeeAccount', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Decline account verification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeny: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerDeny', 'id', id)
            const localVarPath = `/api/v1/users/{id}/deny`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users with their companies and primary address
         * @param {number} page 
         * @param {number} perPage 
         * @param {Array<UsersControllerFindAllAccountStatusEnum>} [accountStatus] 
         * @param {string} [createdAtFrom] 
         * @param {string} [createdAtTo] 
         * @param {string | null} [email] 
         * @param {string | null} [firstName] 
         * @param {string | null} [lastName] 
         * @param {string | null} [businessRelationId] 
         * @param {string | null} [companyId] 
         * @param {string | null} [companyName] 
         * @param {boolean} [emailVerified] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindAll: async (page: number, perPage: number, accountStatus?: Array<UsersControllerFindAllAccountStatusEnum>, createdAtFrom?: string, createdAtTo?: string, email?: string | null, firstName?: string | null, lastName?: string | null, businessRelationId?: string | null, companyId?: string | null, companyName?: string | null, emailVerified?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('usersControllerFindAll', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('usersControllerFindAll', 'perPage', perPage)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountStatus) {
                localVarQueryParameter['accountStatus'] = accountStatus;
            }

            if (createdAtFrom !== undefined) {
                for (const [key, value] of Object.entries(createdAtFrom)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (createdAtTo !== undefined) {
                for (const [key, value] of Object.entries(createdAtTo)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (businessRelationId !== undefined) {
                localVarQueryParameter['businessRelationId'] = businessRelationId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (emailVerified !== undefined) {
                localVarQueryParameter['emailVerified'] = emailVerified;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User with company and roles information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindOne: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user company and contact person.
         * @param {string} id 
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate: async (id: string, updateUserRequestDto: UpdateUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdate', 'id', id)
            // verify required parameter 'updateUserRequestDto' is not null or undefined
            assertParamExists('usersControllerUpdate', 'updateUserRequestDto', updateUserRequestDto)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify the user account with the given id. It connects     the backing company to the user and adds the contact ID. If the given     BusinessRelationID is already connected to a company, the user will be     connected to that company. If the given BusinessRelationID is not connected     to a company, it will be added to the user\'s company and the user will be     verified. Returns the ID of the company that the user is associated with     after verification.     
         * @summary Verify user account
         * @param {string} id 
         * @param {VerifyUserRequestDto} verifyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerify: async (id: string, verifyUserRequestDto: VerifyUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerVerify', 'id', id)
            // verify required parameter 'verifyUserRequestDto' is not null or undefined
            assertParamExists('usersControllerVerify', 'verifyUserRequestDto', verifyUserRequestDto)
            const localVarPath = `/api/v1/users/{id}/verify`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates pending account and send them invitation email
         * @param {CreateAccountFromInvitationDto} createAccountFromInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCreate(createAccountFromInvitationDto: CreateAccountFromInvitationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccountInvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreate(createAccountFromInvitationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteEmployeeAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteEmployeeAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerDeleteEmployeeAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Decline account verification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeny(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeny(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerDeny']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Users with their companies and primary address
         * @param {number} page 
         * @param {number} perPage 
         * @param {Array<UsersControllerFindAllAccountStatusEnum>} [accountStatus] 
         * @param {string} [createdAtFrom] 
         * @param {string} [createdAtTo] 
         * @param {string | null} [email] 
         * @param {string | null} [firstName] 
         * @param {string | null} [lastName] 
         * @param {string | null} [businessRelationId] 
         * @param {string | null} [companyId] 
         * @param {string | null} [companyName] 
         * @param {boolean} [emailVerified] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindAll(page: number, perPage: number, accountStatus?: Array<UsersControllerFindAllAccountStatusEnum>, createdAtFrom?: string, createdAtTo?: string, email?: string | null, firstName?: string | null, lastName?: string | null, businessRelationId?: string | null, companyId?: string | null, companyName?: string | null, emailVerified?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllUsersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindAll(page, perPage, accountStatus, createdAtFrom, createdAtTo, email, firstName, lastName, businessRelationId, companyId, companyName, emailVerified, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary User with company and roles information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindOne(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindOne(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerFindOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update user company and contact person.
         * @param {string} id 
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdate(id: string, updateUserRequestDto: UpdateUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdate(id, updateUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verify the user account with the given id. It connects     the backing company to the user and adds the contact ID. If the given     BusinessRelationID is already connected to a company, the user will be     connected to that company. If the given BusinessRelationID is not connected     to a company, it will be added to the user\'s company and the user will be     verified. Returns the ID of the company that the user is associated with     after verification.     
         * @summary Verify user account
         * @param {string} id 
         * @param {VerifyUserRequestDto} verifyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerVerify(id: string, verifyUserRequestDto: VerifyUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerVerify(id, verifyUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerVerify']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates pending account and send them invitation email
         * @param {CreateAccountFromInvitationDto} createAccountFromInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate(createAccountFromInvitationDto: CreateAccountFromInvitationDto, options?: any): AxiosPromise<CreateAccountInvitationResponseDto> {
            return localVarFp.usersControllerCreate(createAccountFromInvitationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteEmployeeAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerDeleteEmployeeAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Decline account verification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeny(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerDeny(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users with their companies and primary address
         * @param {number} page 
         * @param {number} perPage 
         * @param {Array<UsersControllerFindAllAccountStatusEnum>} [accountStatus] 
         * @param {string} [createdAtFrom] 
         * @param {string} [createdAtTo] 
         * @param {string | null} [email] 
         * @param {string | null} [firstName] 
         * @param {string | null} [lastName] 
         * @param {string | null} [businessRelationId] 
         * @param {string | null} [companyId] 
         * @param {string | null} [companyName] 
         * @param {boolean} [emailVerified] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindAll(page: number, perPage: number, accountStatus?: Array<UsersControllerFindAllAccountStatusEnum>, createdAtFrom?: string, createdAtTo?: string, email?: string | null, firstName?: string | null, lastName?: string | null, businessRelationId?: string | null, companyId?: string | null, companyName?: string | null, emailVerified?: boolean, options?: any): AxiosPromise<GetAllUsersResponseDto> {
            return localVarFp.usersControllerFindAll(page, perPage, accountStatus, createdAtFrom, createdAtTo, email, firstName, lastName, businessRelationId, companyId, companyName, emailVerified, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User with company and roles information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindOne(id: string, options?: any): AxiosPromise<GetUserResponseDto> {
            return localVarFp.usersControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user company and contact person.
         * @param {string} id 
         * @param {UpdateUserRequestDto} updateUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate(id: string, updateUserRequestDto: UpdateUserRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerUpdate(id, updateUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify the user account with the given id. It connects     the backing company to the user and adds the contact ID. If the given     BusinessRelationID is already connected to a company, the user will be     connected to that company. If the given BusinessRelationID is not connected     to a company, it will be added to the user\'s company and the user will be     verified. Returns the ID of the company that the user is associated with     after verification.     
         * @summary Verify user account
         * @param {string} id 
         * @param {VerifyUserRequestDto} verifyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerify(id: string, verifyUserRequestDto: VerifyUserRequestDto, options?: any): AxiosPromise<VerifyResponseDto> {
            return localVarFp.usersControllerVerify(id, verifyUserRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Creates pending account and send them invitation email
     * @param {CreateAccountFromInvitationDto} createAccountFromInvitationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerCreate(createAccountFromInvitationDto: CreateAccountFromInvitationDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerCreate(createAccountFromInvitationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete account and send them a confirmation email
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDeleteEmployeeAccount(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerDeleteEmployeeAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Decline account verification
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDeny(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerDeny(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users with their companies and primary address
     * @param {number} page 
     * @param {number} perPage 
     * @param {Array<UsersControllerFindAllAccountStatusEnum>} [accountStatus] 
     * @param {string} [createdAtFrom] 
     * @param {string} [createdAtTo] 
     * @param {string | null} [email] 
     * @param {string | null} [firstName] 
     * @param {string | null} [lastName] 
     * @param {string | null} [businessRelationId] 
     * @param {string | null} [companyId] 
     * @param {string | null} [companyName] 
     * @param {boolean} [emailVerified] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerFindAll(page: number, perPage: number, accountStatus?: Array<UsersControllerFindAllAccountStatusEnum>, createdAtFrom?: string, createdAtTo?: string, email?: string | null, firstName?: string | null, lastName?: string | null, businessRelationId?: string | null, companyId?: string | null, companyName?: string | null, emailVerified?: boolean, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerFindAll(page, perPage, accountStatus, createdAtFrom, createdAtTo, email, firstName, lastName, businessRelationId, companyId, companyName, emailVerified, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User with company and roles information
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerFindOne(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user company and contact person.
     * @param {string} id 
     * @param {UpdateUserRequestDto} updateUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdate(id: string, updateUserRequestDto: UpdateUserRequestDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdate(id, updateUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify the user account with the given id. It connects     the backing company to the user and adds the contact ID. If the given     BusinessRelationID is already connected to a company, the user will be     connected to that company. If the given BusinessRelationID is not connected     to a company, it will be added to the user\'s company and the user will be     verified. Returns the ID of the company that the user is associated with     after verification.     
     * @summary Verify user account
     * @param {string} id 
     * @param {VerifyUserRequestDto} verifyUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerVerify(id: string, verifyUserRequestDto: VerifyUserRequestDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerVerify(id, verifyUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const UsersControllerFindAllAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;
export type UsersControllerFindAllAccountStatusEnum = typeof UsersControllerFindAllAccountStatusEnum[keyof typeof UsersControllerFindAllAccountStatusEnum];


