import { environment } from '../environments';

export enum MediaQueries {
  BIG_DESKTOP = '(min-width:1600px)',
  DESKTOP = '(min-width:960px)',
  TABLET = '(max-width:960px)',
  MOBILE = '(max-width:600px)',
}

export const ACCOUNT_DELETE_FEATURE = false;
// Contact Assistant;
export const CONTACT_ASSISTANT_EMAIL = 'mygrimme@grimme.de';
export const CONTACT_ASSISTANT_NAME = 'GRIMME Service';
export const CONTACT_ASSISTANT_PHONE = '+49 5491 666 666';
export const CONTACT_ASSISTANT_WHATSAPP = '4916097851151';

// Styling-related and length-related constants;
export const BASE_FADE_TIMEOUT = 1000;
export const BASE_FADE_MULTIPLIER = 350;
export const DARK_NAVBAR_SCROLL_POSITION = 53;
export const DEBOUNCE_LATENCY = 300;
export const DEFAULT_HEADER_HEIGHT = 56;
export const EMPTY_PLACEHOLDER = '';
export const FALLBACK_IMAGE = '/assets/img/fallback_image.png';
export const MACHINE_NAME_CHARACTER_LIMIT = 50;
export const MACHINE_SERIAL_LENGTH = 8;
export const NORMALIZE_LATITUDE = 0.01;

// Default values;
export const DEFAULT_API_ERROR_KEY = 'myGRIMME_grid_errors_unknown';
export const DEFAULT_COUNTRY_CODE = 'DE';
export const DEFAULT_DEALER = {
  City: 'Damme',
  CountryId: 'Damme',
  EMail: 'shop@grimme.de',
  Id: 'BR066492',
  Latitude: 52.516972,
  Longitude: 8.201857,
  Name: 'GRIMME Landmaschinenfabrik GmbH & Co. KG',
  Phone: '+49 (5491) 666-0',
  ShopParticipant: true,
  Street: 'Hunteburger Str. 32',
  Telefax: '+49 (5491) 666-2298',
  Website: environment.grimmeWebsiteUrl,
  ZipCode: '49401',
};
export const DEFAULT_GEOLOCATION = {
  countryCode: DEFAULT_COUNTRY_CODE,
  countryFlag: '/assets/img/flag_de.svg',
  countryName: 'Deutschland',
  latitude: '50.93790',
  longitude: '6.87910',
  zipcode: '49401',
};
export const DEFAULT_CUSTOMER_SERIVCE_EMAIL = 'service@grimme.de';
export const DEFAULT_HECTARES_UNIT = 'ha';
export const DEFAULT_ENGINE_TIME_UNIT = 'h';
export const DEFAULT_MOBILE_PHONE = '+49 5491 666 666';
export const DEFAULT_MACHINE_IMAGE = '/assets/img/default-machine.png';

// Notification messages;
export const DEFAULT_NOTIFICATION_MESSAGE = {
  myGRIMME_core_account_form_failure:
    'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
  myGRIMME_core_account_form_pending: 'Deine Daten werden aktualisiert...',
  myGRIMME_core_account_form_success:
    'Deine Daten wurden erfolgreich aktualisiert.',
  myGRIMME_core_auth_loop:
    'Etwas ist schief gelaufen, bitte melden Sie sich erneut an!',
  myGRIMME_core_cci_disconnected:
    'Die Verbindung zum Terminal wurde erfolgreich unterbrochen.',
  myGRIMME_core_cci_error_connecting:
    'Die Verbindung zum Terminal ist fehlgeschlagen. Bitte überprüfe den Session Code und die Internetverbindung des Terminals und versuche es erneut.',
  myGRIMME_core_create_user_failure:
    'Es ist ein Fehler aufgetreten. Benutzer kann nicht erstellt werden.',
  myGRIMME_core_create_user_success:
    'Neues Benutzerkonto wurde erfolgreich erstellt.',
  myGRIMME_core_delete_user_failure:
    'Es ist ein Fehler aufgetreten. Der Benutzer ist nicht gelöscht worden.',
  myGRIMME_core_delete_user_success: 'Der Benutzer wurde erfolgreich gelöscht.',
  myGRIMME_core_edit_user_failure:
    'Es ist ein Fehler aufgetreten. Der Benutzer wurde nicht aktualisiert.',
  myGRIMME_core_edit_user_pending: 'Die Daten werden aktualisiert...',
  myGRIMME_core_edit_user_success:
    'Der Benutzer wurde erfolgreich aktualisiert.',
  myGRIMME_core_login_failure:
    'Etwas ging schief. Bitte versuchen Sie es noch einmal.',
  myGRIMME_core_login_success: 'Sie wurden erfolgreich eingeloggt.',
  myGRIMME_core_machine_add_failure:
    'Die Maschine konnte nicht hinzugefügt werden. Bitte versuche es später erneut.',
  myGRIMME_core_machine_add_success:
    'Die Maschine wurde erfolgreich hinzugefügt.',
  myGRIMME_core_machine_delete_failure:
    'Es ist ein Fehler aufgetreten. Die Maschine konnte nicht gelöscht werden.',
  myGRIMME_core_machine_delete_success:
    'Die Maschine würde erfolgreich gelöscht.',
  myGRIMME_core_machine_rename_failure:
    'Es ist ein Fehler aufgetreten. Die Maschine konnte nicht umbenannt werden.',
  myGRIMME_core_machine_rename_success:
    'Die Maschine würde erfolgreich umbenannt.',
  myGRIMME_core_profile_fetch_failure:
    'Beim Laden Deiner Daten ist etwas schief gelaufen. Bitte versuche es später erneut.',
  myGRIMME_core_season_form_create_failure:
    'Es ist ein Fehler aufgetreten. Deine neue Kampagne konnte nicht erstellt werden.',
  myGRIMME_core_season_form_create_success:
    'Deine neue Kampagne wurde erfolgreich erstellt.',
  myGRIMME_core_season_form_delete_failure:
    'Es ist ein Fehler aufgetreten. Deine Kampagne konnte nicht gelöscht werden.',
  myGRIMME_core_season_form_delete_success:
    'Deine Kampagne wurde erfolgreich gelöscht.',
  myGRIMME_core_season_form_update_failure:
    'Es ist ein Fehler aufgetreten. Deine Kampagne konnte nicht aktualisiert werden.',
  myGRIMME_core_season_form_update_success:
    'Deine Kampagne wurde erfolgreich aktualisiert.',
};

// Regular Expressions;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^([ \u00c0-\u01ffa-zA-Z'\\-])+$/;
export const PHONE_REGEX = /^(?:\+?[1-9][\d\s]{1,18})?$/;

export enum UserGridPermissions {
  //PERMISSIONS (KEYS). Naming convention: [SECTION]_[CRUD]_PERMISSION_KEY!
  COMPANY_ACCOUNTS_CREATE_PERMISSION_KEY = 'mygrimme.company_accounts.create',
  COMPANY_ACCOUNTS_DELETE_PERMISSION_KEY = 'mygrimme.company_accounts.delete',
  COMPANY_ACCOUNTS_READ_PERMISSION_KEY = 'mygrimme.company_accounts.read',
  COMPANY_ACCOUNTS_UPDATE_PERMISSION_KEY = 'mygrimme.company_accounts.update',
  COMPANY_UPDATE_PERMISSION_KEY = 'mygrimme.company.update',
  CONNECTION_MANAGER_READ_PERMISSION_KEY = 'mygrimme.connections.read',
  CONNECTION_MANAGER_CREATE_PERMISSION_KEY = 'mygrimme.connections.create',
  CONNECTION_MANAGER_UPDATE_PERMISSION_KEY = 'mygrimme.connections.update',
  CONNECTION_MANAGER_DELETE_PERMISSION_KEY = 'mygrimme.connections.delete',
  CONNECTIVITY_UPLINK_READ_PERMISSION_KEY = 'connectivity.uplink.read',
  CONNECTIVITY_UPLINK_UPDATE_PERMISSION_KEY = 'connectivity.uplink.update',
  SEASONS_CREATE_PERMISSION_KEY = 'connectivity.seasons.create',
  SEASONS_UPDATE_PERMISSION_KEY = 'connectivity.seasons.delete',
  SEASONS_DELETE_PERMISSION_KEY = 'connectivity.seasons.update',
  SEASONS_READ_PERMISSION_KEY = 'connectivity.seasons.read',
  MACHINES_OWNERSHIP_READ_PERMISSION_KEY = 'mygrimme.machines.ownerships.get',
  MACHINES_CREATE_PERMISSION_KEY = 'mygrimme.machines.create',
  MACHINES_DELETE_PERMISSION_KEY = 'mygrimme.machines.delete',
  MACHINES_UPDATE_PERMISSION_KEY = 'mygrimme.machines.update',
  MACHINE_SHARING_CREATE_SHARING_PERMISSION_KEY = 'mygrimme.sharing.offer',
  MACHINE_SHARING_DELETE_SHARING_PERMISSION_KEY = 'mygrimme.sharing.delete',
  MACHINE_SHARING_READ_SHARING_PERMISSION_KEY = 'mygrimme.sharing.read',
  //TODO: Rename these keys below up to the naming convention;
  MACHINE_SHARING_ACCEPT_INVITATION_KEY = 'mygrimme.sharing.approve',
  MACHINE_SHARING_DECLINE_INVITATION_KEY = 'mygrimme.sharing.decline',
  MACHINE_SHARING_REQUEST_READ_PERMISSION_KEY = 'mygrimme.sharing.request',

  // Machine Statuses, Currently we have only one status supported, more will come.
  INACTIVE_MACHINE_FLAG = 'inactive',
  // This is deprecated and will be removed later once new permissions are in place.
  TELEMETRY = 'telemetry',
  CONNECTIVITY = 'connectivity',
}

//Local and session storage values;
export const STORAGE_LOCAL_SIDEBAR_COLLAPSED =
  'my.grimme.core-sidebar-collapsed';
export const STORAGE_SESSION_USER_MACHINES =
  'my.grimme.core-user-machines-dashboard';
