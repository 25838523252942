import * as sentry from '@sentry/nextjs';

class SentryService {
  captureException(error: unknown, extra?: Record<string, unknown>) {
    sentry.captureException(error, {
      extra: { originalException: error, ...extra },
    });
  }
}

export const sentryService = new SentryService();
